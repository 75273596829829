.misentireA4sheet {
  width: 200mm; 
  min-height: 200mm; 
  margin: 1% 0% 0% 6%;
  padding: 10mm;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border: 1px solid #ccc;
  box-sizing: border-box;
  page-break-after: always; 
  height: 20vh;
  overflow: scroll;
}


.misA4sheet {
  margin: 0; 
  height: auto; 
  background-color: aliceblue;
  padding-bottom: 20px; 
}

/* Section titles */
#misName {
  font-weight: 400;
  font-size: 1rem;
  text-align: start;
  color: black;
  margin-top: 1%;
  margin-bottom: 1%;
}

/* Table header styling */
.table-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-x: auto;
}

/* Flexbox rows for header and content */
.table-header,
.table-row {
  display: flex;
  flex-direction: row;
}

/* Header columns */
.table-header > div,
.table-row > div {
  flex: 1;
  padding: 8px;
  box-sizing: border-box;
}

/* Header styling */
.table-header {
  background-color: #52447d;
  font-weight: bold;
  color: white;
}

/* Row styling */
.table-row {
  border-bottom: 1px solid #ddd;
}

#misDoctorName {
  font-size: 200%;
  text-align: start;
  color: darkviolet;
  font-weight: bold;
}

#misDoctorPh {
  margin-top: 1%;
  margin-bottom: 1%;
  font-size: 115%;
  text-align: start;
  color: darkviolet;
  font-weight: 900;
}

/* Page break handling for print */
@media print {
  body {
    margin: 0;
    padding: 0;
    width: 210mm;
    height: 297mm;
    overflow: hidden;
  }

  .misentireA4sheet {
    width: 100%;
    height: 100%;
    padding: 0;
  }

  .page-container {
    page-break-before: always; /* Force a page break before each container */
    width: 210mm; /* A4 width */
    min-height: 297mm; /* A4 height */
    margin: 0; /* Reset margin to avoid extra space */
    padding: 20mm; /* Padding for the content */
    box-sizing: border-box; /* Include padding in width/height */
  }
}
