.docMain{
    margin: 0 2%;
}
.entireDocumentPage {
    display: flex;
    flex-direction: column;
    /* height: 78vh; */
    /* margin-left: 4%; */
}

.documentScreen {
    background-color: white;
    border-radius: 3%;
    margin: 2%;
    display: flex;
    flex-direction: row;
    height: 20rem;
    padding: 2%;
    border-radius: 20px;
    /* overflow: scroll; */
    gap: 1rem,;
}
.uploadPdfs{
    flex: 1;
}

.pdfGeneratorScreen {
    display: flex;
    flex: 1;
    width: 30vw;
    margin-top: 2%;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    overflow: scroll;
}

.patientDocument {
    background-color: rgba(227, 237, 255, 1);
    display: flex;
    position: sticky;
    cursor: pointer;
    margin-top: 2%;
    /* margin-left: 2%;
    margin-right: 2%; */
    border-radius: 5px;
}

.backButton {
    display: flex;
    gap: 5px;
    height: 10px;
    align-items: center;
    cursor: pointer;
    margin-top: 1.05rem;
    padding-left: 10px;
}

#image {
    width: 1rem;
    height: 0.8rem;
}

/* .uploadPdfs {
    position: absolute;
    right: 100px;
    left: 6%;
    bottom: 75px;
    display: flex;
    flex-direction: column;
} */

.uploadPdfsSubIcon {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-right: 34%;
    align-content: center;

}

.uploadfileicon {
    margin: 2%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2%;
    width: 100%;
}

/* .iconText {
    background-color: white;
    margin-right: 1%;
    font-size: 80%;
    margin-right: 10%;
    border-radius: 7px;
    color: grey;
} */

.iconButton {
    border: 0;
    background-color: #AF8DFF;
    color: white;
    border-radius: 5px;
    width: 5vw;
    height: 2.2vw;
}

.icon1 {

    display: flex;
    align-items: center;
    gap: 1%;
}

.iconText1 {
    color: grey;
    width: 100%;

}

.iconButton1 {
    display: inline-flex;
    align-items: center;
    border: none;
    background-color: #AF8DFF;
    color: white;
    border-radius: 10px;
    width: 10vw;
    height: 2.5vw;
    cursor: pointer;
    padding-left: 5%;
}

.iconButtonContent {
    display: flex;
    align-items: center;
  }
  
.filePicker {
    /* background-color: blueviolet;
    border-radius: 5px; */
    color: grey;
    /* padding: 1%; */
}

.popupicons {
    background-color: #EBE3FF;
    margin-left: 5%;
    margin-top: 0.5%;
    border-radius: 5px;
    /* border: 1px solid; */
}

.pdfview {
    /* width: 60vw; */
    height: 70vh;
    /* border: 1px solid #ccc; */
    margin: 2%;
    /* margin-top: 2%; */
    /* overflow: scroll; */
    /* margin-left: 3%; */
}

.generatepdf{
display: flex;
background-color: #E3EDFF;
margin-bottom: 3%;
height: 4vw;
width: 25vw;
border-radius: 8px;
padding-left: 2%;
padding-top: 1%;
align-items: center;
}
