.Abtscroll {
    /* height: 70vh; */
    overflow: scroll;
    display: flex;
    flex-direction: column;
    gap: 1%;
  }

.abtverspace{
    display: flex;
    flex-direction: column;
    gap: 2rem
}

.Abtclinallybtn{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
    padding: 0.7rem 3rem;
    border-radius: 6px;
}

.Imgtext{
    display: flex;
    align-items: center;
    gap: 1rem
}

.gapbtwconanver{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    
}


.AboutTextinside {
    font-family: "Varela Round";
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.085em;
    text-align: left;
    display: flex;
    flex-direction: column;
    padding: 2%;
    gap: 1.5rem;
    border: 1px solid #ffc15b;
    border-radius: 6px;
    /* max-height: 350px;  */
    overflow-y: auto;
    background-color: white;
  }