.facilityFormContainer {
    padding: 20px;
    /* max-width: 600px; */
    /* margin: 0 auto; */
    /* border: 1px solid #ddd; */
    border-radius: 8px;
    /* background-color: #f9f9f9; */
    overflow: scroll;
    height: 70vh;
  }
  
  .facilityFormContainer h2 {
    text-align: center;
  }
  
  .formGroup {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    width: 50%;
  }

  .facilitydesc{
    font-size: 16px;
    font-weight: bolder !important;
    line-height: 20px;
    letter-spacing: 0.01em;
    font-weight: 400;
    color: rgba(82, 68, 125, 1);
  }
  
  .formGroup label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  /* .formGroup input,
  .formGroup select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  } */
  
  .facilitySubmit {
    display: block;
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
  }

  .facilityinput{
    width: 78.5%;
    height: 45px;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #ccc;
    border-radius: 10px;
    color: #555;
    background-color: #fff !important;
  }

  .facilityDetailsform{
    width: 100%;
  }

  .forminputs{
    display: flex;
    flex-direction: row;
    margin-bottom: 2%;
  }

  .facilitytypes{
    /* height: 13vh; */
    border-radius: 15px;
    background-color: white;
    color: grey;
    /* text-align: center; */
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    /* padding-left: 3%; */
    border: 1px solid #f7f0ff;
    margin-bottom: 2%;
    margin-top: 2%;
  }

  .facilityheading{
      border-radius: 15px 15px 0px 0px;
      display: flex;
      justify-content: space-between;
      background-color: #f7f0ff;
      height: 3rem;
      align-items: center;
      /* cursor: pointer; */
 
  }

  .facilitysubopt {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; /* Allow wrapping */
    gap: 2%; /* Space between options */
    padding-left: 2%;
    margin-top: 3%;
    margin-bottom: 3%;
    max-width: 100%; /* Ensure it doesn't overflow */
    justify-content: flex-start; /* Align items to the start */
  }
  
  .facilityopt {
    background-color: #f2f2f2;
    padding: 10px;
    border-radius: 8px;
    cursor: pointer;
    text-align: center;
    width: calc(33% - 2%); /* Adjust to 3 per row */
    box-sizing: border-box;
    transition: background-color 0.3s ease;
  }
  
  .facilityopt:hover {
    background-color: #ddd;
  }
  
  .facilityopt.selected {
    background-color: #52447d;
    color: #fff;
  }

  .facilityopt{
    align-items: center;
    display: flex;
    /* font-size: 70%; */
    margin-top: 1%;
    background-color: #d9d9d9;
    color: #52447d;
    border-radius: 30px;
    padding: 1%;
    width: 18%;
    justify-content: center;
    height: 10vh;
    padding: 2%;
  }
  
  .medicinecheckbox{
    display: flex;
    flex-direction: column;
    padding: 1.04% 40% 0.63% 3%;
  }

  