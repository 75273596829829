.image-heading{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  
}


.accordion-item {
  align-items: center;
  margin-bottom: 10px;
  border-radius: 25px 25px 25px 25px;
  /* margin-left: 15px; */
  /* margin-right: 30px ; */
  background-color: 
  #EAE8FF;
  /* width: 810px; */
  /* margin-left: 15%; */
}.accordion-image{
  padding-right: 4%;
  padding-bottom:3%;

}
.heading1{
  padding-top: 7%;
  display: flex;

}

.expand{
  display: flex;
}
.expandimages{
  position: sticky;
  align-items:self-end;
  margin-left: auto;
  margin-right: 3%;
  margin-top: 1.5%;
}
.accordion-heading.active{
  color:blueviolet;
}
.sub-heading.active{
  color: blueviolet;
}

.accordion-heading {
  margin-top: 5%;
  padding : 20px;
  cursor: pointer;
  background-color:
  #EAE8FF;
  border-radius: 25px 25px;
  color:  #52447D;
  font-family: Varela Round;
line-height:   21.67px;
font-weight: 100;
  gap: 1px;
  font-size: 18px;
}
.index-icons{
  display: flex;
}


.icons{
  margin-right: 5px;
  align-items: end;
}

.sub-heading{
  display: flex;
  
  width:90%
}
.accordion-heading h3 {
  margin: 0;
}

.accordion-expansion {
  padding: 20px;
}
.total-accordion{
  overflow: scroll;
}

.lifestyle-Prescription{
 background-color: #F7AB46;
 color: #FFFFFF;
 border: none;
 width: 40%;
 height: 24px;
 padding-top: 2%;
 padding-bottom: 5%;
 font-size: 23px;
 border-radius: 10px 10px 10px 10px;
}
  .lifeStyle-submit{
margin-left: 30%;  }





