/* .popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  width: 100%;
  border: 1px solid #ccc;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  height: 100%;
  background-color: #2a2525b8;
  z-index: 5;
  gap: 1rem;
}

.ContinueBtn {
  background: #52447d;
  width: 170px;
  height: 50px;
  border-radius: 7px;

  font-size: 12px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0.05em;
  color: white;
  border: none;
  cursor: pointer;
}

.popup-inner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 3%;
  width: 31%;
  height: 40%;
  border-radius: 15px;
  border: 1px solid #ccc;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

}

.popup-inner h2{
    font-weight: 900;
    font-size: 1.5rem;
    padding: 2% 15%;
}

.popup-inner p{
    font-weight: 400;
    font-size: 1rem;
    padding: 2% 0%;
    
}


.popup-buttons{
    display: flex;
    justify-content: center;
    gap:3%;
    padding: 5%;
}

.popup-buttons button{

    background: #52447d;
    color: white;
    padding: 3%;
    border-radius: 5%;
    cursor: pointer;
} */

.patientNamePrescription {
  border-radius: 20px 20px 0px 0px;
  /* margin-left: 3%; */
  /* margin-right: 3%; */
  width: auto;
  /* margin-top: 2%; */
  background-color: rgba(227, 237, 255, 1);
  display: flex;
  position: sticky;
}

.entireprescriptionpage {
  margin-top: 1%;
  margin-left: 3%;
  margin-right: 3%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 37px;
  height: 77vh;
  overflow: scroll;
}

.prescriptionpageDrugs {
  /* width: 30vw; */
  margin-top: 2%;
}

.previewtext {
  height: 10vh;
  border-radius: 15px;
  background-color: white;
  color: grey;
  /* text-align: center; */
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  padding-left: 3%;
  border: 1px solid #f7f0ff;
  margin-bottom: 3%;
}

.setfollowuptext {
  text-align: center;
  margin-top: 3%;
}

.PrescriptionBtns {
  display: flex;
  width: 60vw;
  flex-direction: row;
  justify-content: center;
}

.prescriptionborder {
  border: 1px solid #d4d4d4;
  border-radius: 20px;
}

.RecommendationPrescription {
  background-color: #f7f0ff;
  padding: 1%;
  border-radius: 26px;
  width: 30%;
  display: flex;
  justify-content: center;
  margin: 1% 0% 1% 0%;
}

.drugprescription {
  height: 13vh;
  border-radius: 15px;
  background-color: white;
  color: grey;
  /* text-align: center; */
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  /* padding-left: 3%; */
  border: 1px solid #f7f0ff;
  margin-bottom: 3%;
}

.drugnamep {
  border-radius: 15px 15px 0px 0px;
  display: flex;
  justify-content: space-between;

  background-color: #f7f0ff;
  height: 3rem;
  align-items: center;
}

.DeleteBtnPr {
  align-items: center;
  background-color: #ff9095;
  color: white;
  border-radius: 15px;
  width: 5%;
  height: 2%;
  width: 5vw;
  height: 3vh;
  border: none;
}

.DrugDetailsPre {
  display: flex;
  flex-direction: row;
  gap: 2%;
  padding-left: 2%;
  margin-top: 2%;
  margin-bottom: 4%;
}

.DrugNamesPre {
  align-items: center;
  display: flex;
  font-size: 70%;
  margin-top: 1%;
  background-color: #d9d9d9;
  color: #52447d;
  border-radius: 25px;
  padding: 1%;
  width: 25%;
  justify-content: center;
}
