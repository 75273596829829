.side-navHeader {
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  color: #52447d;
  width: 250px;
  z-index:0;
}

h2 {
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
}

.side-navItems {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  height: 99vh;
  /* position: fixed;*/
}

/* .side-navItems {
  width: 92%;
} */

.logo {
  width: 100px;
  height: 100px;
  margin-top: 10px;
}

.navItemLink {
  display: flex;
}

.buttonContainer.active ,.buttonContainersmall .active  {
  display: flex;
  background-color: #fbf8ff;
  width: 100%;
  justify-content: space-between;
}


.navItemIcon {
  width: 30px;
  margin-right: 15px;
  margin-left: 20px;
}

.toggle-button.active {
  border-radius: 30px;
  font-size: 1.1rem;
  transition: all 0.2s ease-in-out;
  outline: none;
  line-height: 18px;
  color: rgba(82, 68, 125, 1);
}

.toggle-button {
  cursor: pointer;
  background: transparent;
  font-size: 1.1rem;
  font-weight: 400;
  color: rgba(186, 186, 186, 1);
  border: 1px solid transparent;
  box-sizing: content-box;
  z-index: 5;
  outline: none;
  white-space: nowrap;
  line-height: 1px;
}

.small-navbar-icon {
  justify-content: center !important;
}

.buttonContainer,.buttonContainersmall {
  display: flex;
  flex-direction: row;
  /* text-align: center;
  justify-content: center;
  align-items: center; */
  justify-content: flex-start;
  width: 230px;
  padding: 10px 0px;
  width: 107%;
  color: 
  rgba(186, 186, 186, 1);
  /* align-items: center; */
}

.navItemIconContainer{
  display: flex;
  align-items: center;
  width:90%;
  /* gap: 10%; */
}

.underline {
  color: #52447d;
  border-right: 3px solid blueviolet;
  /* width: 20px; */
  /* border-radius: 1.5rem; */
}

.sideNavMenu {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 110px;
}

/* .logout-menu {
  color: red;
} */

.profileicon {
  margin-left: 15px;
  padding-right: 15px;
}




.logoContainer {
  display: flex;
  padding-bottom: 15%;
  flex-direction: column;
  width: 100%;
}

.logoToggle{
  border: none;
  background: none;
  margin: 20% 0;
}

.logo-small {
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.logo-large {
  width: 100px;
  height: 100px;
  cursor: pointer;
}

.sideNavTopMenu{
  display: flex;
  flex-direction: column;
}

.RightArrow{
  height: 27px;
  background: #e6dcdc;
  /* background: rgb(222, 186, 127); */
  width: 27px;
  /* right: 0; */
  position: absolute;
  margin-left: 14.5%;
  /* border: 1px solid black; */
  border-radius: 50%;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}


.leftArrow{
  height: 27px;
  background: #e6dcdc;
  /* background: rgb(222, 186, 127); */
  width: 27px;
  /* right: 0; */
  position: absolute;
  margin-left: 4.5%;
  /* border: 1px solid black; */
  border-radius: 50%;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.logo-small, .logo-large {
  transition: all 1s ease; 
}

@media screen and (max-width: 1236px) {
  .toggle-button {
    /* padding: 7px 10px; */
    cursor: pointer;
    background: transparent;
    font-size: 1rem;
    font-weight: 400;
    color: rgba(186, 186, 186, 1);
    border: 1px solid transparent;
    box-sizing: content-box;
    z-index: 5;
    outline: none;
    white-space: nowrap;
    line-height: 1px;
    margin-left: 8%;
  }

  .toggle-button.active {
    border-radius: 30px;
    transition: all 0.2s ease-in-out;
    outline: none;
    line-height: 18px;
    color: rgba(82, 68, 125, 1);
    /* font-size: 13px; */
    margin-left: 8%;
    font-size: 1rem;
  }

  .navItemIcon {
    width: 18px;
    margin-right: 0px;
    margin-left: 7px;
  }

  .underline {
    color: #52447d;
    border-right: 3px solid blueviolet;
    /* width: 20px; */
    /* border-radius: 1.5rem; */
  }
  .logo-large {
    width: 70px;
    height: 70px;
    cursor: pointer;
    
  }
}

@media screen and (max-width: 1000px) {
  .toggle-button {
    /* padding: 7px 10px; */
    cursor: pointer;
    background: transparent;
    /* font-size: 9px; */
    font-weight: 400;
    color: rgba(186, 186, 186, 1);
    border: 1px solid transparent;
    box-sizing: content-box;
    z-index: 5;
    outline: none;
    white-space: nowrap;
    line-height: 1px;
    font-size: 11px;
        margin-left: 7px;
  }

  .toggle-button.active {
    border-radius: 30px;
    transition: all 0.2s ease-in-out;
    outline: none;
    line-height: 18px;
    color: rgba(82, 68, 125, 1);
    /* font-size: 9px; */
    font-size: 11px;
        margin-left: 7px;
  }

  .navItemIcon {
    width: 16px;
    margin-right: 0px;
    margin-left: 5px;
  }

  .underline {
    color: #52447d;
    border-right: 3px solid blueviolet;
    /* width: 20px; */
    /* border-radius: 1.5rem; */
  }
  .logo-large {
    width: 70px;
    height: 70px;
    cursor: pointer;
    
  }
}

@media screen and (max-width: 1235px) {
.buttonContainersmall .active {
  display: flex;
  background-color: #fbf8ff;
  width: 100%;
  justify-content: center;
}

/* .buttonContainersmall {
  display: flex;
  background-color: #fbf8ff;
  width: 100%;
  justify-content: center;
} */
}