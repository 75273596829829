.mainStatuscompleteProfile{
    flex: 1.3;
    margin: 2rem 2rem 2rem 0;
}
.completeProfile{
    border: 1px solid #b6b6b6;
    border-radius: 16px;
    padding: 1.5rem 1.5rem; 
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    height: 73vh;
  
    /* height: %; */
    /* margin: 3% 2%; */
    align-items: center;
}

.completeProfile p {
    color: rgba(82, 68, 125, 1);
}

.CircularProgressbar{
    width: 50%;
    height: 20vh;
    vertical-align: middle;
}

.Complete-information{
    width: 100%;
}

.Complete-information-inner{
    margin: 7% 3%;
    display: flex;
    gap: 5%;
}

.profile-completion{
    display: flex;
}

.Profile-Heading{
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 28.6px;
}

.CircularProgressbar .CircularProgressbar-text{
    fill: rgba(0, 0, 0, 1);
    font-size: 2rem;
}

.CircularProgressbar .CircularProgressbar-path{
    stroke: rgba(82, 68, 125, 1);
    transition: stroke-dashoffset 0.5s ease 0s;
}