.ConsolidateReport table {
    height: 17vh;
    border: 1px solid fuchsia;
    border-collapse: collapse;
}

.ConsolidateReport th {
    height: 9vh;
    max-width: 100vw;
    border: 1px solid;
    border-collapse: collapse;
    /* border-color: fuchsia; */
    font-size: 1rem;
    padding: 0 0.2rem;
}

.ConsolidateReport td {
    height: 6vh;
    max-width: 100vw;
    border: 1px solid;
    border-collapse: collapse;
    /* border-color: fuchsia; */
    font-size: 1rem;
    padding: 0 0.2rem;
}

.entireA4sheet {
    justify-content: center;
    height: 86vh,
}

.A4sheet {
    margin-left: 4%;
    margin-right: 4%;
    height: 80vh;
    overflow: scroll;
}

.patientNameInReport {
    width: auto;
    margin-top: 3%;
    background-color: rgba(227, 237, 255, 1);
    display: flex;
    /* position: sticky; */
}

.backButton {
    cursor: pointer;
    display: flex;
    gap: 5px;
    height: 10px;
    align-items: center;
    cursor: pointer;
    margin-top: 1.05rem;
    padding-left: 10px;
}

.ConsolidatedReport {
    margin-right: 2%;
    margin-left: 2%;
    /* width: 595.28px;
    color: black; */
    /* background-color: white; */
}

.patientReport {
    text-align: center;
    /* margin: 5%; */
    /* padding: 5%; */
    /* box-shadow: 3px 1px 30px 3px grey; */
    margin-top: 5%;
}

#DoctorName {
    font-size: 200%;
    text-align: start;
    color: darkviolet;
    font-weight: bold;

}

#DoctorPh {
    margin-top: 1%;
    margin-bottom: 1%;
    font-size: 115%;
    text-align: start;
    color: darkviolet;
    font-weight: 900;
}

/* hr {
    margin-top: 3%;
    width: 70vw;
    border: 1px solid;
} */

#patientVisitHistory {
    font-weight: 700;
    font-size: 150%;
    text-align: start;
    color: darkviolet;
    margin-top: 1%;
    margin-bottom: 1%;
}

.patientData1 {
    /* width: 70vw;     */
    align-items: center;
    text-align: center;
    height: 20vh;
    background-color: black;
    display: flex;
    
}

.patientData2 {
    margin: 1%;
    padding-left: 2%;
}

.patientData3 {
    margin: 1%;
    padding-left: 15%;
}

#patient {
    color: white;
    font-weight: 35;
    text-align: start;
}

.patientData {
    background-color: ghostwhite;
    text-align: center;
    box-shadow: 3px 1px 30px 3px grey;
}

.downloadButton{
    align-items: center;
    width: 12vw;
    height: 8vh;
    margin-top: 5%;
    /* margin-left: 45%; */
    background-color: darkviolet;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    color: aliceblue;
}
.downloadButtonmain{
    display: flex;
    align-items: center;
    justify-content: center;
}
#line{
    margin-top: 2%;
    width: 70vw;
    border: 1px solid lightgrey; 
}