.radioBtnTextGap {
  display: flex;
  gap: 1rem;
}

.abhaContainerLeft {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 20px;
}

.Abhanumfield {
  display: flex;
  flex-direction: column;
  padding-left: 25%;
  gap: 1rem;
}

.OtpBtn {
  color: white;
  font-size: 17px;
  font-weight: 400;
  line-height: 20.47px;
}

.NHAandimage {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 0.5rem;
}

.Adharandmobile {
  display: flex;
  flex-direction: row;
}

.SkipAbhatext {
  font-family: Varela Round;
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  color: #52447d;
  text-decoration: underline;
  cursor: pointer;
}
/* Custom styles for OTP input */
.otp-input {
  display: flex;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  text-align: center;
}

.otp-input input {
  display: flex;
}

.css-wb57ya-MuiFormControl-root-MuiTextField-root {
  min-width: 100% !important;
}

.css-q8hpuo-MuiFormControl-root {
  min-width: 100% !important;
}

.AbhaSetupInputs {
  width: 100%;
  padding: 8px;
  border: 2px solid rgba(214, 229, 255, 1);
  border-radius: 10px;
}
