/*Inital Assessment css starting */

.initalAssementMainContainer {
  display: flex;
  border-radius: 20px;
  /* width: 60vw; */
  /* padding: 1rem; */
  flex-direction: column;
  /* gap: 1rem; */
  /* overflow: scroll; */
  border: 1px solid rgba(227, 237, 255, 1);
  margin-bottom: 2%;
}

.initalAssementHeading {
  background-color: rgba(227, 237, 255, 1);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.initalAssementHeading h3 {
  margin: 2%;
  color: rgba(9, 74, 115, 1);
}

.initalAssessmentIteams {
  /* background-color: #F7F3FE; */
  display: flex;
  justify-content: space-between;
  border-radius: 25px;
  /* position: sticky; */
  /* white-space: nowrap; */
  flex-direction: column;
}

.initalAssessmentButtons {
  background-color: #f9fbff;
  display: flex;
  justify-content: flex-start;
  padding: 2%;
  gap: 2%;
}

/*Inital Assessment css ending */

.notregtext {
  color: gray;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.035rem;
}

.gendertext {
  padding-bottom: 5px;
  text-align: center;
}

.nametxt {
  color: #000;
  font-size: 1.5rem;
  font-weight: 400;
  letter-spacing: -0.0075rem;
}

.Circlearoundnameicon {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  margin: 6% 3% 6% 6%;
}

.lastChecked {
  color: #868686;

  font-size: 12px;
  font-weight: 400;
  letter-spacing: -0.0075rem;
  display: flex;
  flex-direction: column;
  gap: 11px;
  margin: 2% 0;
}
.lastChecked span {
  color: #094a73;

  margin: 0;
  padding: 0;
  font-size: 16px;
}
.homePatients {
  display: flex;
  flex-direction: column;
  background-color: white !important;
  min-height: 86vh;
}

.homePatient {
  display: flex;
  flex-direction: column;
  background-color: white;
  height: 79vh;
  /* padding-top: 1rem; */
  justify-content: center;
}

.homeBody {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: space-between;
  justify-content: center;
  padding: 0%;
  background: transparent;
  position: relative;
  z-index: 2;
  /* padding-bottom: 40px; */
}

.homeBackground {
  position: absolute;
  object-fit: cover;
  bottom: 0;
  left: -65px;
}

.homeBackground img {
  height: 85vh;
  width: 85vw;
}

.patientsHeading {
  display: flex;
  justify-content: space-between;
}

.appointmentHeading {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0.12em;
  text-align: center;
  color: #536df2;
  font-weight: 400;
  /* text-decoration: underline; */
  padding-bottom: 10px;
  padding-left: 10px;
}

.containerPatientsList {
  display: flex;
  gap: 18px;
  margin-bottom: 10px;
  /* justify-content: space-around; */
  /* background-color: #cbfff2; */
  align-items: center;
  padding: 7px 12px;
  border-radius: 11px;
  cursor: pointer;
}

.shortName1 {
  /* background-color: #4affe9; */
  border: 4px solid white;
  border-radius: 50%;
  /* padding: 5px 6px */
  height: 2.5rem;
  width: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
}

.patientsBodyContainerAPM {
  display: flex;
  align-items: center;
  gap: 20px;

  /* background-color: #fff4f4; */
  /* background-color: #fff8ea; */
  /* padding: 20px 44px; */
  border-bottom-left-radius: 11px;
  border-bottom-right-radius: 11px;
  /* box-shadow: 0px 0px 250px -23px #5300ee40; */
  overflow: scroll;
}

.patientDetail {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  /* align-items: center;    */
  /* text-align: center; */
  /* border: 2px solid #4affe9; */
  border-radius: 14px;
  padding: 15px;
  position: relative;
  overflow: scroll;
  /* width: 33.33%; */
  background-color: white;
}

.patientDetailInAppointments {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  /* align-items: center;    */
  /* text-align: center; */
  border: 2px solid #4affe9;
  border-radius: 14px;
  padding: 15px;
  position: relative;
  overflow: scroll;
  width: 80%;
  background-color: white;
}

.hospitalDetail {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  /* align-items: center;    */
  /* text-align: center; */
  border: 2px solid #4affe9;
  border-radius: 14px;
  padding: 15px;
  position: relative;
  overflow: scroll;
  /* width: 50%; */
  background-color: white;
}

/* .css-ahj2mt-MuiTypography-root{
    padding-top:20px;
} */

.toggle-buttonTab {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Varela Round;
  font-size: 17px;
  font-weight: 400;
  line-height: 20px;
  background-color: transparent;
  letter-spacing: 0em;
  text-align: left;
  color: #5d4a87;
  /* width: 213px; */
  padding: 0 1rem;
  padding: 1.3rem 2rem !important;
  height: 25px;
  border: none;
  cursor: pointer;
  border: 1px solid rgba(232, 224, 255, 1);
  border-radius: 8px;
}

.review {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Varela Round;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  background-color: transparent;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(9, 74, 115, 1);
  /* width: 213px; */
  padding: 0 1rem;
  height: 45px;
  border: none;
  cursor: pointer;
  font-weight: bold;
}

.patient_data {
  width: 30%;
  /* height: fit-content; */
  border-radius: 11px;
  background-color: white;
  border: 1px solid rgba(255, 255, 255, 1);
}

.patient_data_text {
  display: flex;
  justify-content: center;
  padding: 0.55rem;
  background-color: rgba(247, 240, 255, 1);
  border-radius: 15px 15px 0px 0px;
  /* color: white; */

  font-family: Varela Round;
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
}

.text-data {
  font-family: Varela Round;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #52447d;
}

.toggle-buttonTab.activeTab {
  font-family: Varela Round;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
  background: #5d4a87;
  border-radius: 8px;
  cursor: pointer;
  padding: 1.3rem 2rem;
}

.threeDots {
  width: 5px;
  height: 20px;
  text-align: right;
  position: absolute;
  top: 20px;
  right: 20px;
}

.vector {
  width: 63px;
  border: 2px solid #dcdcdc;
  display: flex;
  justify-content: center;
}

.issues {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 10px;
  border: 2px solid #4affe9;
  border-radius: 14px;
  padding: 12px 26px;
  background-color: #d9fffa;
  max-width: 260px;
}

.issues p {
  font-size: 10px;
  font-weight: 700;
  line-height: 9px;
  letter-spacing: 0.01em;
}

.issuesContainer {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.issuesContainer p {
  background-color: #c8d7ff;
  font-size: 0.625rem;
  padding: 3px 10px;
  border-radius: 13px;
}

.issuesVector {
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
}

.issuesVector p {
  width: 200px;
  margin-top: 10px;
  border: 1px solid #60e7d5;
}

.time {
  background-color: #4affe9;
  padding: 2px 10px;
  border-radius: 5px;
  color: black !important;
  font-size: 13px;
}

.issuesDetails {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1rem;
  padding: 2rem;
}

.visitsAndTime {
  display: flex;
  gap: 10px;
  align-items: center;
}

.visitsAndTime p {
  color: #0b47e1;
  font-weight: bold;
  font-size: 0.8125rem;
  /* white-space: nowrap; */
}

.link {
  display: flex;
  /* padding-left: 49%; */
  padding-bottom: 4px;
}

/* for padding bottom add patient */
.link1 {
  display: flex;
  padding: 0 25%;
}

.patientsListContainer {
  /* border: 2px solid #4affe9; */
  /* background-color: white; */
  /* padding: 1% 4%; */
  border-radius: 20px;
  overflow: scroll;
  /* width: 33.33%; */
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
}

.patientName {
  /* font-size: 1.5rem; */
  white-space: collapse balance;
  font-size: 15px;
}

.patientHeading {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0.12em;
  text-align: center;
  color: #536df2;
  text-decoration: underline;
  padding-bottom: 10px;
  padding-left: 10px;
}

.searchContainer {
  margin: 20px 0 0 0;
  padding: 0 1.5rem;
  display: flex;
  gap: 30px;
  /* border-bottom: 2px solid #67BFFF; */
}

.searchContainerBorder {
  padding: 0 1.5rem;
}

.searchContainerBorder p {
  border-bottom: 2px solid #67bfff;
  margin-bottom: 1rem;
}

.searchContainer input {
  outline: none;
  border: none;
  width: 100%;
  /* color: #BEBEBE; */
  color: #000;
  background: transparent;
}

.initialassessmentboxbg {
  display: flex;
  flex-direction: column;
  background-color: #F5F4FF;
  padding: 10px;
}

.ia-spacebetween {
  display: flex;
  justify-content: space-between;
}

.row {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.testProfileContainer {
  width: 100px;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  box-shadow: 0px 0px 4px 0px #0034824d;
  border-radius: 10px;
}

.noteaddappbox {
  display: flex;
  justify-content: center;
  background: #f2f2f2;
  align-items: center;
  width: 30%;
  margin-left: 7%;
  border-radius: 2px;
  height: 5vh;
}

.botbox {
  display: flex;
  background: white;
  padding-bottom: 2%;
}

.col {
  display: flex;
  flex-direction: column;
  gap: 4px;
  color: #52447d;
  font-size: 14px;
  padding: 1% 4%;
  margin-top: 6%;
}

/* .imgContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 4px 5px 0px #00348270;
  border-radius: 5px;
  width: 23px;
  height: 23px;
} */
.imgContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  /* box-shadow: 0px 4px 5px 0px #00348270; */
  border-radius: 5px;
  width: 23px;
  height: 23px;
  border: 1px solid #00000029;
}

.columnData {
  display: flex;
  align-items: center;
  gap: 10px;
}

.patientListItem {
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 10px;
  width: 100%;
  height: 63px;
  /* border-radius: 10px; */
  border: 1px;
  background: #FFFFFF;
  border: 1px solid #EDEDED;
}

.appointmentsDetails {
  overflow: scroll;
  width: 33.33%;
}

.observation {
  line-height: 2rem;
}

.addAppointmentsBtn {
  background: #52447d;
  width: 150px;
  height: 40px;
  border-radius: 23px;
  /* background: linear-gradient(180deg, #6688ff 0%, #4b3e77 100%); */
  font-size: 12px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0.05em;
  color: white;
  border: none;
  cursor: pointer;
  border: 1px solid #52447D;
}

/* for bottom add patient button */

.addAppointmentsBtn1 {
  background: #52447d;
  width: 210px;
  height: 50px;
  border-radius: 7px;
  /* background: linear-gradient(180deg, #6688ff 0%, #4b3e77 100%); */
  font-size: 12px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0.05em;
  color: white;
  border: none;
  cursor: pointer;
}

/* .addAppointmentsBtn:hover {
  box-shadow: 0px 3px 16px 1px #52447d75;
} */

.examinationText {
  color: #7d7d7d;
  font-family: Varela Round;
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.Btn {
  background: #52447d;
  width: 210px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
  font-size: 12px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0.05em;
  color: white;
  border: none;
  cursor: pointer;
}

/* Followups Some Css Part */

.SearchBox {
  position: relative;
  width: 56%;
  display: flex;
  align-items: center;
  padding-left: 20%;
}

/* ADD NEW VISIT BUTTON */

.AddvisitBtn {
  /* color: white; */
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 24px;
  font-size: 10px;
  width: 7rem;
  background: transparent;
  cursor: pointer;
  color: #52447d;
  border: 1px solid #52447d;
  background-color: white;
  cursor: pointer;
}

/*********new patient css**********/

.addPatientBtn {
  font-size: 1rem;
  width: 15%;
  height: 5%;
  border-radius: 10px;
  padding: 0.5%;
  background-color: rgba(82, 68, 125, 1);
  color: rgba(255, 255, 255, 1);
  cursor: pointer;
  border: none;
  margin-right: 0.5%;
}

.patient-search-addpatient {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2%;
  /* margin-bottom: 1%; */
  padding-bottom:20px;
}

.patient-search input {
  border: none;
  width: 80%;
  margin: 2%;
}

.patient-search input::placeholder {
  color: rgba(170, 170, 170, 1);
  font-size: 1rem;
}

.patient-search {
  background-color: white;
  display: flex;
  flex-direction: row;
  border: 1px solid rgba(206, 206, 206, 1);
  border-radius: 25px;
  align-items: center;
  gap: 3%;
  width: 25%;
  justify-content: flex-start;
  padding: 0.5%;
}

.Patient-MainContainer {
  display: flex;
  justify-content: space-evenly;
  width: 100vw;
}

.Patient-TopNavbar {
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
}

.Patient-heading {
  font-weight: bold;
  font-size: 1.3rem;
  line-height: 30px;
  color: rgba(82, 68, 125, 1);
}

.Patient-TableComponent {
  height: 75vh;
  overflow: scroll;
}

 ::-webkit-scrollbar{
  width: 5px;
}

::-webkit-scrollbar-thumb{
  background:rgba(146, 137, 248, 0.6);
} 

.patients-Body-Container {
  display: flex;
  align-items: center;
  gap: 20px;
  height: 75vh;
  border-bottom-left-radius: 11px;
  border-bottom-right-radius: 11px;
  box-shadow: 0px 0px 250px -23px #5300ee40;
  overflow: scroll;
  box-shadow: none !important;
}

.Patient-container {
  /* height: 92.5vh; */
  display: flex;
  flex-direction: column;
  background-color: #f9fbff;
  padding: 1% 1% 0;
  gap: 20px;
  width: 100%;
}

.patient-addBtn {
  width: 20%;
  /* padding:1%; */
}

.searchBox-patient {
  padding-top: 1%;
  border: none;
  display: flex;
  color: "#BEBEBE";
}

.searchBox-patient:focus {
  border: none;
  outline: none;
}

.Patient-search-main {
  gap: 5%;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
}

.manageAbha {
  display: flex;
  border-radius: 1.75rem;
  background: rgba(247, 240, 255, 1);
  padding: 5px 20px;
  color: rgba(9, 74, 115, 1);
  font-size: 0.6875rem;
  white-space: nowrap;
  justify-content: center;
  border: none;
}

.manageAbha:hover {
  background: rgba(9, 74, 115, 1);

  color: white;
  cursor: pointer;
}

.linkAbha {
  display: flex;
  border-radius: 1.75rem;
  background: rgba(247, 240, 255, 1);
  padding: 5px 20px;
  color: rgba(9, 74, 115, 1);
  font-size: 0.6875rem;
  white-space: nowrap;
  justify-content: center;
  border: none;
}

.linkAbha:hover {
  background: rgba(9, 74, 115, 1);
  color: white;
  cursor: pointer;
}

.single-patient-main {
  display: flex;
}

.Patient-profile-details {
  width: 25%;
  border: 1px solid rgba(212, 212, 212, 1);
  border-radius: 10px;
}

.Patient-LinkAbha {
  /* width: 16%; */
  padding: 2%;
  padding-left: 8%;
  padding-right: 8%;
  border-radius: 12px;
  font-size: 0.5rem;
  color: white;
  background-color: rgba(82, 68, 125, 1);
  cursor: pointer;
  border: none;
}

.shortName {
  background-color: rgba(214, 200, 255, 1);
  border-radius: 50%;
  /* padding: 5px 6px */
  height: 2.5rem;
  width: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
}

.nameContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 51px; */
  /* height: 53px; */
  border-radius: 50%;
  /* border: 1px solid #4affe9; */
  flex-direction: column;
}

.patient-button {
  display: flex;
  justify-content: flex-end;
  margin: 2% 2% 0% 0%;
}

.patient-personal-details {
  display: flex;
  justify-content: center;
  color: rgba(9, 74, 115, 1);
  font-size: 0.6rem;
  padding: 1%;
  border-radius: 10px;
  background-color: rgba(247, 240, 255, 1);
  width: 27%;
  margin-top: 4%;
  margin-bottom: 3%;
  padding-left: 3%;
  font-weight: bold;
}

.Patient-AG {
  display: flex;
  gap: 50%;
}

.patient-heading {
  color: rgba(134, 134, 134, 1);
  font-size: 0.8rem;
}

.patient-value {
  color: rgba(9, 74, 115, 1);
}

.patient-AG-container {
  display: flex;
  flex-direction: column;
  gap: 25px;
  padding-left: 3%;
  margin-bottom: 5%;
}

.patient-patientID {
  display: flex;
  align-items: center;
}

/*****************/
/* src/CustomTable.css */
.patient-visit-table .Visittable {
  width: 100%;
  border-collapse: collapse;
  margin: 10px 0;
  font-size: 1em;
  /* min-width: 400px; */
  /* margin-top: 10%; */
}

.Visittable thead {
  background-color: rgba(247, 240, 255, 1);
}
.Visittable th {
  font-size: 15px;
  color: rgba(9, 74, 115, 1);
  padding: 1%;
}

.Visittable td {
  color: rgba(9, 74, 115, 1);
  font-size: 10px;
  font-weight: bold;
  border-bottom: 1px solid rgba(206, 206, 206, 1);
  padding-left: 5%;
}

.prescription-button {
  border: none;
  color: rgba(9, 74, 115, 1);
  padding: 4px 8px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 10px;
  margin: 2px 1px;
  cursor: pointer;
  border-radius: 4px;
  background-color: rgba(243, 234, 255, 1);
  border-radius: 15px;
}

.prescription-button:hover {
  background-color: #d6d6ff;
}

.TableComponent{
  margin-left: 0.5%;
  padding: 0% 1%;
}


/*****************/
@media screen and (max-width: 800px) {
  .toggle-buttonTab {
    padding: 0.5rem 0.8rem !important;
  }
  .toggle-buttonTab .activeTab {
    padding: 0.5rem 0.8rem !important;
  }
}
@media screen and (max-width: 1005px) {
  .toggle-buttonTab {
    padding: 0.7rem 1.2rem !important;
  }
  .toggle-buttonTab .activeTab {
    padding: 0.7rem 1.2rem !important;
  }
}
@media screen and (max-width: 1350px) {
  .toggle-buttonTab {
    padding: 1.2rem 1.5rem !important;
    font-size: 16px !important;
  }
  .toggle-buttonTab .activeTab {
    padding: 1.2rem 1.5rem !important;
    font-size: 16px !important;
  }
}
@media screen and (max-width: 1236px) {
  .toggle-buttonTab .activeTab {
    font-size: 1rem;
    padding: 1rem 1.1rem !important;
  }
  .addPatientBtn {
    font-size: 13px !important;
    width: 25%;
    /* height: 5%; */
    border-radius: 10px;
    padding: 0.8%;
    background-color: rgba(82, 68, 125, 1);
    color: rgba(255, 255, 255, 1);
    cursor: pointer;
  }

  .patient-search input {
    border: none;
    width: 80%;
    margin: -1%;
    border-radius: 25px;
    padding-left: 1%;
  }

  .patient-search {
    background-color: white;
    display: flex;
    flex-direction: row;
    border: 1px solid rgba(206, 206, 206, 1);
    border-radius: 25px;
    align-items: center;
    gap: 3%;
    width: 30%;
    justify-content: flex-start;
    padding: 0.5%;
  }

  .prescription-button {
    border: none;
    color: rgba(9, 74, 115, 1);
    padding: 2px 3px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 7px;
    margin: 2px 1px;
    cursor: pointer;
    border-radius: 4px;
    background-color: rgba(243, 234, 255, 1);
    border-radius: 15px;
  }

  .Visittable th {
    font-size: 9px;
    color: rgba(9, 74, 115, 1);
    padding: 1%;
  }

  .Visittable td {
    color: rgba(9, 74, 115, 1);
    font-size: 8px;
    font-weight: bold;
    border-bottom: 1px solid rgba(206, 206, 206, 1);
    padding-left: 5%;
  }

  .toggle-buttonTab {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    padding: 1rem 1.1rem !important;
    font-family: Varela Round;
    font-size: 10px;
    font-weight: 400;
    line-height: 20px;
    background-color: transparent;
    letter-spacing: 0em;
    text-align: left;
    color: #5d4a87;
    /* width: 213px; */
    padding: 0 1rem;
    height: 25px;
    border: none;
    cursor: pointer;
    border: 1px solid rgba(232, 224, 255, 1);
    border-radius: 8px !important;
    padding: 0.8rem 1.6rem;
  }
}

@media screen and (max-width: 1178px) {
  .toggle-buttonTab {
    padding: 1rem 1.5rem !important;
    font-size: 13px !important;
 
  }
  .toggle-buttonTab .activeTab {
    padding: 1.2rem 1.5rem !important;
    font-size: 16px !important;
  }
}
/* changes css new  */
.VisitesListContainer {
  flex: 1;
  margin: 2% 0%;
  border: 2px solid #d4d4d4;
  border-radius: 10px;
  /* width: 18%; */
}

.allDataContainerBox {
  flex: 2.4;
  height: 100vh;
  margin: 2% 1.5%;
  border: 2px solid #d4d4d4;
  border-radius: 10px;
  /* width: 49%; */
}

.patientsDetilaContainer {
  flex: 1.3;
  overflow: scroll;
  /* border: 2px solid #D4D4D4;
border-radius: 10px; */
  /* height: 100vh; */
  margin: 1% 0;
  border: 2px solid #d4d4d4;
  border-radius: 10px;
  /* width: 20%; */
}
/* .patientsDetilaContainer1 { */
  /* flex: 1;
  overflow: scroll; */

  /* height: 100vh;
  margin: 2% 0; */
/* } */

.mainpageComponent {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #f9fbff;
  height: 100vh;
}
/* .profileImageTabDetailsContainer { */
  /* margin-top: 5%; */
/* } */
/* .profileImageTabDetails { */
  /* text-align: center; */
/* } */
.profileImageTabDetails .patientName {
  font-size: 1.2rem;
}
.profileImageTabDetails .patientId {
  font-size: 0.6rem;
}

.imagepatientDetail {
  display: flex;
  align-items: center;
  width: 100%;
}

.profileImageTabDetailsId {
  font-size: 10px;
  color: #9c9aa5;
  margin-top: 3%;
  border-bottom: 1px solid;
}
.profileImageTabDetails .patientId span {
  color: #094a73;
}

.personalDetailDataMainContainer {
  color: white;
  background: #fbaf42;
  display: flex;
  justify-content: space-between;
  padding: 1% 5%;
  align-items: center;
  font-size: 18px;
  margin-top: 5%;
}
.personalDetailDataMainContainer:hover {
  cursor: pointer;
}
.personalDetailDataMainContainerInner {
  display: flex;
  padding: 5%;
  flex-direction: column;
}
.personalDetailDataMainContainerInner .inner1 {
  display: flex;
  padding-right: 10%;
  gap: 53%;
}
/* .M3functionality { */
  /* padding: 1% 4%; */
/* } */
.M3functionality .row {
  background-color: #d6e5ff;
  border-radius: 10px;
  color: #094a73;
  padding: 0.7rem;
  font-size: 0.8rem;
  display: flex;
  margin: 5% 0;
  justify-content: space-between;
  /* padding-right: 6%; */
}

.imagepatientDetailouter{
  display: flex;
  align-items: center;
  align-items: flex-start;
  justify-content: space-between;
}
@media screen and (max-width: 1236px) {
  .row {
    gap: 0;
  }
  .profileImageTabDetails .patientName {
    font-size: 0.7rem;
  }
  .profileImageTabDetailsId {
    font-size: 0.5rem;
  }
  .profileImageTabDetails .patientId {
    font-size: 0.4rem;
  }
  .profileediting {
    /* position: absolute; */
    /* top: 0; */
    width: 15px;
    height: 15px;
    cursor: pointer;
    margin-right: 3%;
    margin-bottom: 21%;
  }
}

@media screen and (max-width: 1060px) {
  .text-data {
    font-size: 9px;
  }
}

.nopatients {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: 80vh;
  background: #9987873d;
}
.nopatients1 {
  text-align: center;
}

.profileediting {
  width: 20px;
  height: 20px;
  border: 1px solid black;
  cursor: pointer;
  padding: 1%;
  /* margin-right: 2.5%;
  margin-bottom: 21.5%; */
  border-radius: 3px;
}


.notABHaDetails{
  display: flex;
  text-align: center;
  justify-content: center;
  margin-top: 3%;
}