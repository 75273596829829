.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f2f2f2; 
}

.table-striped tbody tr:nth-of-type(even) {
  background-color:rgba(242, 247, 255, 1); 
}
  
  
  
  .main-ManageABHASubscription-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    border: 1px solid #bcbbbb;
    border-radius: 19px;
  }
  .ManageABHASubscription-container {
    display: flex;
    /* gap: 5%; */
    /* margin: 1%; */
    width: 100%;
    height: 75vh;
  
    /* background: rgba(238, 232, 255, 1); */
    /* box-shadow: 2px 1px 5px 10px rgba(241, 238, 250, 1); */
    border-radius: 20px;
    /* padding: 1%; */
    flex-direction: column;
    align-items: center;
  }

  .Titleheading-Subscription{
    background-color:rgb(227, 237, 255) ;
    width: 100%;
    height: 10%;
    border-radius: 18px 18px 0px 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 3%;
    padding: 2%;

  }

  .ABHASubscription-table{
    margin: 1%;
    display: flex;
    flex-direction: column;
    gap: 1%;
  }

  .ManageABHASubscription-maincontent{
    margin-bottom: 2%;
  }
  
/* ****** New css ****** */


  .AbhaSubscriptionButtonouter{
    display: flex;
    padding: 0.7rem;
    justify-content: center;
    background: white;
    border-radius: 20px;
  }


  .data-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .data-table th, .data-table td {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  .data-table th {
    background-color: #FBAF42;
    text-align: left;
  }
  
 