/* CustomButton.css */
.Custom-create-button {
    /* Add your button styles here */
    background-color: #52447d;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
}

/* .Custom-create-button:hover {
    background-color: #0056b3;
} */
