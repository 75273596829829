.ButtonCVD {
  display: flex;
  gap: 4%;
  margin-top: 1%;
  margin-bottom: 1%;
}

.buttonstyleCVD{
    display: flex;
    flex-direction: column;
    gap: 15px;
}

@media screen and (max-width: 1196px) {
  .ButtonCVD {
    display: flex;
    gap: 4%;
    margin-top: 1%;
    margin-bottom: 1%;
    flex-direction: column;
    gap: 10px;
  }
}
