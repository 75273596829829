.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  width: 100%;
  border: 1px solid #ccc;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  height: 100%;
  background-color: #2a2525b8;
  z-index: 100;
  gap: 1rem;
}

.ContinueBtn {
  background: #52447d;
  width: 170px;
  height: 50px;
  border-radius: 7px;
  /* background: linear-gradient(180deg, #6688ff 0%, #4b3e77 100%); */
  font-size: 12px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0.05em;
  color: white;
  border: none;
  cursor: pointer;
}

.popup-inner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 3%;
  width: 31%;
  height: 40%;
  border-radius: 15px;
  border: 1px solid #ccc;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

}

.popup-inner h2{
    font-weight: 900;
    font-size: 1.5rem;
    padding: 2% 15%;
}

.popup-inner p{
    font-weight: 400;
    font-size: 1rem;
    padding: 2% 0%;
    
}


.popup-buttons{
    display: flex;
    justify-content: center;
    gap:3%;
    padding: 5%;
}

.popup-buttons button{
    /* background-color: #rgb(136, 115, 201); */
    background: #52447d;
    color: white;
    padding: 3%;
    border-radius: 5%;
    cursor: pointer;
}

/* @media  (max-width: 1100) { */

  .ReviewContainer{
    flex-wrap: wrap !important;
  }
  
/* } */

.dht-strength-input-text:hover {
  border-color: #000 !important;
}

.dht-strength-input-text::placeholder {
  color: #999;
}

