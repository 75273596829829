.searchContainerHosp {
  margin: 12px 0 0 0;
  /* padding: 0 1.5rem; */
  display: flex;
  justify-content: space-between;
  gap: 36px;
  border-bottom: 2px solid #67bfff;
  width: 33vw;
}

.searchContainerBorderHosp {
  padding: 0 1.5rem;
}

.searchContainerBorderHosp p {
  border-bottom: 2px solid #67bfff;
  margin-bottom: 1rem;
}

.menuDotsIcon{
  angle: 90 deg;
  color: #094A73;
  width: 21px;
  height: 21px;
  padding: 0px 2.62px 0px 2.63px;
}

.menudots-section{
  
  width: 30px;
  height: 30px;
  background-color: #F4F4F4;
  border-radius:30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.searchContainerHosp input {
  width: 69%;
  outline: none;
  border: none;
  /* width: 100%; */
  /* color: #BEBEBE; */
  color: #000;
  background: transparent;
}

.shortNameHosp {
  background-color: #8873c9;
  border-radius: 50%;
  /* padding: 5px 6px */
  height: 2.5rem;
  width: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  color: white;
}

.hospName {
  /* font-size: 1.5rem; */
  white-space: collapse balance;
  font-size: 21px;
  width: 170px;
  height: 23px;
  
  font-family: Outfit;
  font-size: 18px;
  font-weight: 500;
  line-height: 22.68px;
  text-align: left;
  color: #696969;
}

.hospCityName {
  /* font-size: 1.5rem; */
  white-space: collapse balance;
  font-size: 14px;
  color: #9d9d9d;
  font-weight: 400;
}

.hospNameContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.hospitald-hospNameContainer {
  display: flex;
  align-items: center;
  border-radius: 50%;
}

.hospitald-hospitalName{
  width:20%;
}

.hd-linkAbdm button {
  border-radius: 4px;
  background: #ffc15b;
  padding: 5px 20px;
  color: #52447d;
  font-size: 13px;
  font-weight: 400;
  white-space: nowrap;
  border: none;
  cursor: pointer;
}

.disassociate button {
  /* background: #fdc3c5; */
  /* padding: 5px 20px; */
  color: white;
  font-size: 13px;
  font-weight: 400;
  white-space: nowrap;
  border: none;
  cursor: pointer;
  width: 100%;
  height: 34px;
  border-radius: 17px;
  
  background-color: #FF7777;
  text-align: center;
}

.disassociate-detail{
  font-family: Outfit;
  font-size: 15px;
  font-weight: 500;
  line-height: 18.9px;
  text-align: center;
  color: #FFFFFF;
}

.cancelRequest button {
  background-color: #edc618;
  /* padding: 5px 20px; */
  color: white;
  font-size: 13px;
  font-weight: 400;
  white-space: nowrap;
  border: none;
  cursor: pointer;
  text-align: center;
  border-radius: 17px;
  height: 34px;
  
  width:100%;
}

.cancel-request-detail{
  font-family: Outfit;
  font-size: 15px;
  font-weight: 500;
  line-height: 18.9px;
  text-align: center;
  color: #FFFFFF;
}

.associate button {
  /* padding: 5px 20px; */
  color: white;
  font-size: 13px;
  font-weight: 400;
  white-space: nowrap;
  border: none;
  cursor: pointer;
  width: 100%;
  height: 34px;
  border-radius: 17px;
  
  background-color: #7AD09C;
  text-align:center;
}

.associate-detail{
  font-family: Outfit;
  font-size: 15px;
  font-weight: 500;
  line-height: 18.9px;
  text-align: center;
  color: #FFFFFF;
}
.linkAbdm{
  color: white;
  font-size: 13px;
  font-weight: 400;
  white-space: nowrap;
  border: none;
  cursor: pointer;
  width: 100%;
  height: 34px;
  border-radius: 17px;
  
  background-color: #b7c1f9;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.linkAbdm-detail{
  font-family: Outfit;
  font-size: 15px;
  font-weight: 500;
  line-height: 18.9px;
  text-align: center;
  border:none;
}

.cancelReq {
  border-radius: 4px;
  border: 1px solid #52447d;
  padding: 5px 20px;
  color: #52447d;
  font-size: 13px;
  font-weight: 400;
  white-space: nowrap;
}

.containerHospitalsList {
  display: flex;
  gap: 18px;
  margin-bottom: 10px;
  /* justify-content: space-around; */
  /* background-color: #cbfff2; */
  align-items: center;
  padding: 7px 12px;
  /* cursor: pointer; */
  /* width: 80%; */
  width: 1143px;
  height: 63px;
  border-radius: 10px;
  border: 1px;
  
  background-color: #FCFCFC;
  border: 1px solid #EDEDED;
}


.hospitald-containerHospitalsList {
  display: flex;
  gap: 3%;
  margin-bottom: 10px;
  align-items: center;
  padding: 0px 1%;
  height: 63px;
  border-radius: 10px;
  border: 1px;
  
  background-color: #FCFCFC;
  border: 1px solid #EDEDED;
}

.innerupdatehospitalsection{
  display:flex; 
  flex-direction:column;
  width: 40%;
  /* height: 747px; */
  border: 1px 0px 0px 0px;
  
  border: 1px solid #D4D4D4;
  margin-top:26px;
  border-radius:5px;
}

.columnsNames{
  display:flex;
  justify-content: space-around;
  align-items: center;
  font-family: Lato;
  font-size: 10px;
  font-weight: 700;
  line-height: 12px;
  text-align: center;
  color: #094A73;
  background-color: #F7F0FF;
  height: 32px;
  margin-bottom: 5px;
}

.hospitalcardfirstname, .hospitalcardmobilenumber{
  width: 80px;
  padding-left: 1%;
  height: 12px;
  top: 262px;
  left: 1253px;
  gap: 0px;
  
  font-family: Lato;
  font-size: 10px;
  font-weight: 700;
  line-height: 12px;
  text-align: left;
  color: #52447D;
}

.hospitalcardmobileNumber{
  width: 63px;
  height: 12px;
  top: 262px;
  left: 1382px;
  gap: 0px;
  
  font-family: Lato;
  font-size: 10px;
  font-weight: 700;
  line-height: 12px;
  text-align: left;
  color: #52447D;
}

.hospitalcardstatus{
  width: 71px;
  height: 19px;
  border-radius: 9.5px;
  
  background-color: #F3EAFF;
  font-family: Lato;
  font-size: 10px;
  font-weight: 500;
  line-height: 12px;
  text-align: center;
  color: #094A73;
  display:flex;
  align-items: center;
  justify-content: center;
}

.hospitalcardadmin{
  width: 71px;
  height: 19px;
  border-radius: 9.5px;
  
  background-color: #F3EAFF;
  font-family: Lato;
  font-size: 10px;
  font-weight: 500;
  line-height: 12px;
  text-align: center;
  color: #094A73;
  display:flex;
  align-items: center;
  justify-content: center;
  border:none;
}

.hospitalcardapproved{
  width: 71px;
  height: 19px;
  border-radius: 9.5px;
  
  background-color: #F3EAFF;
  font-family: Lato;
  font-size: 10px;
  font-weight: 500;
  line-height: 12px;
  text-align: center;
  color: #094A73;
  display:flex;
  align-items: center;
  justify-content: center;
}


.hospiotalListItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
  cursor: pointer;
}
.verticalLine{
  width: 0px;
  height: 61.5px;
  border: 1px;
  border: 1px solid #EDEDED;
  margin-left: 15px;
  
}

.list-column-names{
  display:flex;
  justify-content: space-around;
  gap:4%;
  /* margin-left:-88px; */
}

.hospital-d-th {
  height: 23px;
  font-family: Outfit;
  font-size: 1.13rem;;
  font-weight: 500;
  line-height: 22.68px;
  text-align: left;
  color: #C1C1C1;
}
.addAppointmentsBtn{
  margin-right:35px;
}

.clearButton{
  font-family: Outfit;
  font-size: 20px;
  font-weight: 400;
  line-height: 25.2px;
  text-align: center;
  color: #FFFFFF;
  background-color: transparent;
  border-radius: 10px;
}

.closeButton{
  font-family: Outfit;
  font-size: 20px;
  font-weight: 400;
  line-height: 25.2px;
  text-align: center;
  border-radius: 10px;
}

.buttons-section{
  display:flex;
  justify-content: flex-end;
  width:100%;
}

.horizontal-line{
  width: 100%;
  height: 0px;
  border: 1px;
  
  border: 1px solid #B5BFF9;
  margin-bottom: 7px;
}

.hospitalDetailsInput {
  color: #979797;
  font-family: Varela Round;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.1125rem;
  border-radius: 0.375rem;
  /* border: 1px solid #8873c9; */
  width: 100%;
  padding: 0.5rem 1rem;
  background-color: #eeecf5;
  border: none;
}

.otherInput {
  color: #52447d;
  font-family: Vazirmatn;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.1125rem;
  border-radius: 7px;
  /* border: 1px solid #52447d; */
  width: 18vw;
  padding: 0.9rem 1.2rem;
  background-color: #FFF;
  border: 1px solid #DFDFDF;
}
.otherInput::placeholder {
  font-size: 15px;
}

.css-1nmdiq5-menu {
  height: 10vh !important;
  /* padding-bottom: 100px; */
  /* overflow: scroll; */
}

.css-1nmdiq5-menu div {
  /* height: 10vh !important; */
  /* padding-bottom: 10px; */
  overflow: scroll !important;
  background-color: white;
}

/* update hospitals */
.Hospitalboard-main {
  /* padding-left: 3%; */
  padding: 2% 3%;
  /* height: 80vh; */
  overflow: scroll;
  /* flex: 3; */
  width: 100%;
  display: flex;
  height:76vh;
  /* width: 70%;  */
}
/* .hospital-dashboard {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  height: 306px;
  top: 218px;
  left: 167px;
  gap: 0px;
  border-radius: 25px;
  
 
  background-color: rgba(208, 199, 248, 1);
  display: flex;
} */

.hospital-dashboard {
  display: flex;
  flex-direction: column;
  /* justify-content: space-evenly; */
  width: 100%;
  /* height: 50%; */
  gap: 0px;
  border-radius: 25px;
  
  
}

.user {
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 16.8px;
  text-align: left;
  height: 40px;
  border-radius: 4.0px 4.0px 0px 0px;
  
  background-color: #FBAF42;
  color: #FFFFFF;
  padding-left:10px;
  display:flex;
  align-items: center;
}

.Hosptial-card {
  display: flex;
  width: 15%;
  border: rgba(74, 255, 233, 1) solid 1px;
  border-radius: 23px;
  margin-bottom: 2%;
  flex-direction: column;
  align-items: center;
}

/* .Hospital-users{
  width: 1055px;
  height: 306px;
} */

.backBtn {
  width: 12%;
  padding: 1%;
  margin: 0.5%;
  border-radius: 9px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 2%;
  background-color:#52447D ;
  color: white;
  margin-bottom: 2%;
}

.shortname {
  width: 150px;
  display: flex;
  height: 150px;
  top: 642px;
  left: 212px;
  gap: 0px;
  
  background-color: rgba(208, 199, 248, 1);
  border-radius: 200px;
  margin-left: 2%;
  justify-content: center;
}

.shortname h3 {
  font-size: 4rem;
  padding-top: 26%;
  /* padding-left: 23%; */
  font-weight: 100;
}

/* .hospital-image img {
  margin-left: 10%;
  margin-top: 11%;
} */

.hospital-Name {
  /* margin-left: 7%;
  margin-right: 11%;
  padding-top: 5%; */
  margin-top:2%;
}

.hospital-Name h3 {
  font-size: 2rem;
  font-weight: 600;
  line-height: 43px;
}

.hospital-Name p {
  margin-top: 8%;
  color: rgba(58, 58, 58, 1);
}

.hospital-buttons {
  display: flex;
  flex-direction: row;
  gap: 4%;
  width: 70%;
}

.hospital-information {
  /* margin-left: 10%; */
  /* margin-top: 4%; */
  margin-right: 2%;
  width: 100%;
  display:flex;
  flex-direction: column;
}

.bg-hospital{
  display:flex;
  align-items: center;
  gap: 2%;
}

.bg-width-container{
  width:100% !important;
}

.hospital-buttons button {
  width: 142px;
  font-size: 1rem;
  font-weight: 500;
  height: 32px;
  border-radius: 9px;
  border: 2px solid #52447D;
  color: rgba(255, 255, 255, 1);
  cursor: pointer;
  background-color: #52447F;
}

.setdefaultbutton{
  background-color:  #52447F !important;
  color: white !important;
}

.hospital-user {
  color: white;
  background-color: #D6E5FF;
  width: 100px;
  height: 100px;
  top: 396px;
  left: 844px;
  gap: 0px;
  justify-content: center;
  align-items: center;
  border-radius: 9px;
  display: flex;
  text-align: center;
  /* justify-content: center; */
  
  flex-direction: column;
}

.hospital-user p {
  line-height: 24px;
  font-weight: 600;
  font-size: 1rem;
  line-height: 24px;
  font-weight: 600;
  font-size: 0.7rem;
  width: 78px;
  height: 24px;
  border-radius: 15px;
  
  background-color: #FBAF42;
  color: black;
}
.hospital-user h3 {
  /* margin-left: 35%; */
  margin-top: 5%;
  font-weight: 600;
  line-height: 48px;
  font-size: 2rem;
  color:black;
}
.bg-width-container .hospitalname-inputfeild {
  width: 95%;
}
.hospitalname-inputfeild{
  width: 90%;
  height: 52px;
  border-radius: 10px;
  border: 1px;
  border: 1px solid #DFDFDF;
  padding-left:20px;
}

.hospital-label{
  width: 97px;
  height: 16px;
  top: 473px;
  left: 466px;
  gap: 0px;
  
  font-family: Outfit;
  font-size: 13px;
  font-weight: 400;
  line-height: 16.38px;
  text-align: left;
  color: #52447D;
}

.hospitaldetailscontainer{
  display:flex;
  flex-direction: column;
  gap:5px;
  width:50%;
}

.user-section{
  width: 100px;
  height: 24px;
  border-radius: 15px;
  
  background-color: #FBAF42;
  color:black;
}
.upper-padding{
  margin-top:20px;
}

.main-section{
  display:flex;
  margin-bottom: 2%;
  width: 100%;
}

.hosptial-count {
  display: flex;
  margin-top: 4%;
  gap: 7%;
}

.hospitalmain-details-container {
  width:60%;
}

.details-person {
  margin-top: 13%;
  display: flex;
  gap: 10%;
  flex-direction: column;
  align-items: center;
}

.details-person p {
  /* margin-top: 13%; */
  /* margin-bottom: 13%; */
  margin-bottom: 30px;
}

.users-button {
  background-color: #ffc72c;
  color: black;
  padding: 8%;
  /* margin-left: 28%; */
  border-radius: 10px;
  font-size: 1rem;
  font-weight: bold;
  border: none;
  /* margin-left: 20%; */
}

.users-approvbutton {
  background-color: rgba(255, 86, 86, 1);
  color: black;
  padding: 8%;
  /* margin-left: 28%; */
  border-radius: 10px;
  font-size: 1rem;
  font-weight: bold;
  border: none;
  cursor: pointer;
  /* margin-left: 20%; */
}

/* hospitalpopup */

.hospitalpopup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  width: 100%;
  border: 1px solid #ccc;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  height: 100%;
  background-color: #2a2525b8;
  z-index: 5;
}

.hospitalpopup-inner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  /* padding: 3%; */
  width: 31%;
  height: 35%;
  padding:5px;
  border-radius: 15px;
  border: 1px solid #ccc;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hospitalpopup-inner h2 {
  font-weight: 900;
  font-size: 1.5rem;
  margin-top: 3%;
  margin-bottom: 5%;
  /* padding: 2% 15%; */
  /* margin: 2% 30%; */
}

.hospitalpopup-inner p {
  font-weight: 400;
  font-size: 1rem;
  padding: 2% 6%;
}

.hospitalpopup-buttons {
  display: flex;
  justify-content: center;
  gap: 15%;
  padding: 5%;
}

.hospitalpopup-buttons button {
  /* background-color: #rgb(136, 115, 201); */
    border-radius: 5%;
    cursor: pointer;
    background: #52447d;
    width: 150px;
    height: 40px;
    border-radius: 10px;
    /* background: linear-gradient(180deg, #6688ff 0%, #4b3e77 100%); */
    font-size: 12px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0.05em;
    color: white;
    border: none;
    cursor: pointer;
}

.Hospital-cards {
  /* display: flex; */
  overflow: auto;
  /* width: 1060px; */
  /* gap: 5%; */
  /* width: 100%; */
  text-align: center;
  display: grid;
  grid-template-rows: repeat(3, 1fr);
}

.Hospital-card {
  /* margin-left: 2%;
  width: 16%; */
  padding-bottom: 3%;
  /* margin-right: 5%; */
  display: flex;
  align-items: center;
  /* height: 46vh; */
  justify-content: space-around;
  margin-bottom: 3px;
}

.hospital-imagecontainer {
  /* gap: 4%; */
  display: grid;
  align-items: center;
  height: 100%;
  display: grid;
}

.hospitalimagelabelContainer, .hospital_image {
  grid-area: 1/1;
}

.Photo-upload {
  display: flex;
  flex-direction: column-reverse;
}

.Photo-upload {
  width: 80%;
}

.hospital_image {
  display: flex;
  flex-direction: column-reverse;
  gap: 10%;
  height: 100%;
  margin-bottom: 5%;
  align-items: center;
  width: 100%;
}

.manageButtons {
  display: flex;
  gap: 3%;
}

.hospital-log {
  border-radius: 50%;
  width: 100px;
  height: 100px;
}

.hospitalimagelabelContainer img {
  width: 16px;
  height: 16px;
}
.hospitalimagelabelContainer {

    text-align: end;
    width: 89px;
    /* margin: 0 auto; */
    vertical-align: top;
    /* padding-right: 0px; */
    height: 93px;
    z-index:1;
}

.hospitalimagelabel {
  /* display: flex; */
  align-items: center;
  justify-content: center;
  /* width: 80%; */
  border: 1px solid #52447d;
  border-radius: 50px;
  gap: 6%;
  width:18px;
  height:18px;
  display: inline-block;
  background-color: white;
}

.hospitalimagelabel:hover {
  border-color: #66afe9;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
}

.hospitalImagefile {
  border-radius: 50%;
  width: 73%;
  height: 72%;
}

.edit-hospital{
  width: 80%;
  align-content: center; 
  font-weight:bolder;
}

/*Edit hospitals details*/

.edit-container {
  margin-top: 3%;
  border-radius: 1.5rem;
  background-color: #FFF;
  /* border: 1px solid #6ea9ff; */
  width: 90%;
  max-width: 767px;
  margin:0 auto;
  /* margin-bottom: 5%; */
  /* margin: 2% 15% 5% 15%; */
  flex: 1;
}

.edit-heading {
  color: #52447d;
  font-size: 1.4375rem;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.14375rem;
  padding: 10px 20px;
  /* text-align: center; */
}

.edit-form {
  display: flex;
  flex-direction: column;
  height: 85%;
  align-items: center;
  padding: 1rem 2rem;
  justify-content: space-evenly;
  margin: 0% 10%;
}

.edit-input {
  /* color: #979797; */
  font-family: Varela Round;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.1125rem;
  border-radius: 0.375rem;
  border: 1px solid #DFDFDF;
  width: 100%;
  padding: 0.5rem 1rem;
  margin-top:7px;
  margin-bottom: 7px;
}

.last-select-option{
  margin-top:7px;
}

.add-button {
  background: #52447d;
  width: 125px;
  height: 35px;
  border-radius: 7px;
  /* background: linear-gradient(180deg, #6688ff 0%, #4b3e77 100%); */
  font-size: 12px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0.05em;
  color: white;
  border: none;
  cursor: pointer;
  margin-left: 75%;
}

.close-icon {
  /* background: #52447d; */
  color: white;
  padding: 0.2rem 0.4rem;
  border-radius: 4px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.editdiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 2%;
  margin-right: 20px;
}
.edit-headingdiv {
  width: 100%;
  /* border-bottom: 3px solid #52447d; */
  /* display: inline-block; */
  /* padding-bottom: 5px; */
}

.form-edit{
  width: 47%;
  /* display: flex; */
}


.hospital-detaials-row {
  display: flex;
  align-items: center;
}

.hospital-d-th.hd-profile {
  /* width:9%; */
  padding-left: 1%;
}
.hospital-d-th.name {
  width:20%;
}
.hospital-d-th.hfr {
  width:18%;
}
.hospital-d-th.hpr {
  width:18%;
}
.hospital-d-th.hd-link {
  width:35%;
  display:flex;
}

.hospital-d-th.hd-link-child {
  width:50%;

}

.hospitalModalSection{
    border-radius: 0.8rem;
    box-Shadow: 0px 0px 8px 0px rgba(47, 155, 255, 0.34);
    width: 880px;
    background-color: #FFFFFF;
    margin: 0 auto;
    height:450px;
    min-width: 736px;
    margin-top:9%;
     /* display: flex;
     flex-Direction: column; */
  
}

.hospitalModalHeading{
    border-radius: 0.8rem 0.8rem 0rem 0rem;
    border: 1px solid #ACC5EA;
    letter-spacing: 0.14375rem;
    padding: 0.5rem 1rem;
    display: flex;
    justify-content: space-between;
    font-family: Outfit;
    font-size: 18px;
    font-weight: 400;
    line-height: 22.68px;
    text-align: left;
    color: #52447D;
    background-color: #D6E5FF;

}
.hospitalFormContainer{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 80%;
    align-Items: center;

}

.hospitalFormInnerContainer{
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
}

.hospitalFormInnerSecondContainer{
  padding: 1rem 2rem;
  display: flex;
  flex-direction: row;
  gap: 2.0rem;
}

.inner-container{
  display:flex;
  flex-direction: row;
  gap:15px;
  }

.label-container{
    display:flex;
    flex-direction: column;
}

.input-feild-container{
  width: 354px;
  height: 73px;
  top: 87px;
  left: 57px;
  gap: 0px;
  border-radius: 10px ;
  border: 1px;
  
  }

.popup-input-text{
  width: 354px;
  height: 45px;
  border-radius: 10px;
  border: 1px;
  
  border: 1px solid #DFDFDF;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.name-description{
padding-bottom: 5px;
}

.space{
  margin-bottom: 10px;
  width:90%;
}
.next-space{
  margin-bottom: 10px;
  width:100%;
}

.width-space{
  width:90%;
}

.hospital-d-td.hd-profile {
  /* width:9%;
  padding-left: 1%; */
}
.hospital-d-td.name {
  width:20%;
}
.hospital-d-td.hfr {
  width:18%;
}
.hospital-d-td.hpr {
  width:18%;
}
.hospital-d-td.hd-link {
  width: 35%;
  display: flex;
  align-items: center;
}
.hospital-d-td.hd-link-child {
  width:47%;
}

.disassociate-detail{
  cursor: none;
}


@media only screen and (max-width: 1024px)  {

  .linkAbdm-detail {
    font-size: 0.7rem;
  }
  .disassociate button {
    font-size: 0.7rem ;
  }
  .associate button {
    font-size: 0.7rem;
  }
  .Hospitalboard-main{
    flex-direction: column;
  }
  .innerupdatehospitalsection {
    width:100%;
  }
  .hospitalmain-details-container {
    width:100%;
  }
}

@media only screen and (max-width: 880px) {
  .hospitalModalSection {
    width: 64%;
  }
  .Hospitalboard-main{
    flex-direction: column;
  }
}

@media only screen and (max-width: 770px) {

  .popup-input-text{
    width:296px;
  }
  .hospital-d-td {
    font-size: 0.8rem;
  }

  .list-column-names{
    justify-content: unset; 
    gap: 0%;
  }

  .hospital-d-th.name {
    margin-left: 1%;
  }

  .verticalLine {
    margin-left: 9px;
  }

  .hospital-d-th {
    font-size: 0.8rem;
  }

  .shortNameHosp {
    height: 1.5rem;
    width: 1.5rem;
    font-size: 0.8rem;
  }

  .linkAbdm-detail{
    font-family: Outfit;
    font-size: 8px;
    font-weight: 500;
    line-height: 18.9px;
    text-align: center;
    border:none
  }

  .disassociate button {
    /* background: #fdc3c5; */
    /* padding: 5px 20px; */
    color: white;
    font-size: 7px;
    font-weight: 400;
    white-space: nowrap;
    border: none;
    cursor: pointer;
    width: 100%;
    height: 24px;
    border-radius: 17px;
    
    background-color: #FF7777;
    text-align: center;
  }

  .associate button {
    /* padding: 5px 20px; */
    color: white;
    font-size: 8px;
    font-weight: 400;
    white-space: nowrap;
    border: none;
    cursor: pointer;
    width: 100%;
    height: 24px;
    border-radius: 17px;
    
    background-color: #7AD09C;
    text-align:center;
  }

  .cancelRequest button {
    background-color: #edc618;
    /* padding: 5px 20px; */
    color: white;
    font-size: 7px;
    font-weight: 400;
    white-space: nowrap;
    border: none;
    cursor: pointer;
    text-align: center;
    border-radius: 17px;
    height: 24px;
    
    width:100%;
  }

  .linkAbdm{
    color: white;
    font-weight: 400;
    white-space: nowrap;
    border: none;
    cursor: pointer;
    width: 100%;
    height: 24px;
    border-radius: 17px;
    
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .linkAbdm-detail{
    font-family: Outfit;
    font-size: 0.5rem;
    font-weight: 500;
    line-height: 11.0px;
    text-align: center;
    border:none
  }
  
}

