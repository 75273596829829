.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 85vh;
    background-color: #f6f6f6;
  }
  
  .navbar-container {
    width: 100%;
  }
  
  .container {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    width: 100%;
    justify-content: center;
    padding: 0 1%;
  }
  
  .column {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    flex: 1;
    height: 60vh;
    justify-content: center;
  }
  
  /* .column h2 {
    font-family: "Varela Round";
    font-weight: bold;
    line-height: 100%;
  } */
  
  .column h2.title {
    font-size: 1.7rem;
    color: #0b4876;
  }
  
  .column h2.subtitle {
    font-size: 1rem;
    align-content: center;
    
    
  }
  
  .text-ABHA{
      font-size: 0.9rem;
  }
  
  .subheading-ayshman{
      /* color: black; */
      color: rgba(11, 72, 118, 1);
      font-size: 1.5rem;
  }
  
  .icon-container {
    display: flex;
    align-items: center;
    gap: 54%;
    width: 70%;
    margin-left: 9%;
    height: 20%;
    justify-content: flex-end;
  }
  
  .icon {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 5%;
  }
  
  .icon img {
    height: 6rem;
    /* width: 100%; */
  }
  
  .icon p {
    color: #5d4a87;
  }
  
  .text-container {
    font-family: "Varela Round";
    font-weight: 400;
    line-height: 21px;
    font-size: 15px;
    justify-content: center;
    color: rgba(79, 79, 79, 1);
    padding: 0 12%;
  }
  
  .card {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    border: 2px solid #d6c8ff;
    background-color: white;
    border-radius: 30px;
    flex: 1;
    height: 60vh;
    align-items: center;
    justify-content: center;
    /* margin-right: 10%; */
  }
  
  .card p {
    font-family: "Varela Round";
    color: #0b4876;
    font-size: 15px;
    font-weight: bold;
    text-align: center;
    /* padding-top: 30px; */
  }
  
  .button-container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    /* padding: 0 1rem; */
    width: 100%;
    align-content: center;
    align-items: center;
  }
  
  .button-container .button {
    border: 1px solid #8e44ad;
    width: 80%;
    font-weight: 400;
    line-height: 25px;
  }

  .button-container .button.secondary {
    color: white;
    background-color: #52447d;
  }
  
  .button-container .button.primary {
    color: white;
    background-color: #52447d;
  }
  

  
  .approved {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-top: 10%;
  }
  
  .approved h3 {
    font-family: "Varela Round";
    font-size: 1.3rem;
    color: #0b4876;
    width: 200%;
  }
  
  .approved img {
    width: 100%;
    height: 50px;
  }
  
  .otp-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
  }
  
  .otp-container .code {
    display: flex;
    gap: 10%;
    column-gap: 10px;
  }
  
  .otp-container .code .box {
    border: 1px solid #808080;
    border-radius: 5px;
    height: 5vh;
    width: 3vw;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .abha-card-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .abha-card-container .info {
    padding-right: 100px;
    padding-left: 70px;
  }
  
  .abha-card-container .info h2 {
    font-family: "Varela Round";
    font-size: 29px;
    color: #0b4876;
    font-weight: bold;
    margin-top: 70px;
  }
  
  .abha-card-container .info h2.subtitle {
    font-size: 24px;
    padding-left: 30px;
    padding-right: 100px;
  }
  
  .abha-card-container .card-details {
    display: flex;
    padding-left: 50px;
  }
  
  .abha-card-container .card-details .image {
    padding-right: 30px;
  }
  
  .abha-card-container .card-details .details {
    padding-top: 30px;
    padding-left: 20px;
    display: grid;
  }
  
  .abha-card-container .details p {
    color: #5d4a87;
    font-weight: bold;
    margin-top: 0;
  }
  
  .qr-code {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* gap: 2%; */
    width: 50%;
    height: 60vh;
    justify-content: space-evenly;
  }
  
  .qr-code-container {
    border: 2px solid #d6c8ff;
    border-radius: 30px;
    width: 50%;
    height: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    gap: 3%;
    margin-right: 10%;
  }
  
  .qr-code-container .close-button {
    border: 1px solid #0b4876;
    /* margin-left: 80%; */
    border-radius: 25px;
    color: #0b4876;
    /* margin-top: 5%; */
    width: 16%;
  }
  .qr-code-container .topnavinContainer{
    display: flex;
    width: 65%;
    justify-content: space-between;
    align-items: center;
    margin-top: 4%;
  }
  .qr-code-container .qr-code {
    background-color: #d3d3d3;
    width: 400px;
    height: 250px;
    margin-top: 20px;
    margin-left: 100px;
  }
  
  .qr-code-container .details {
    display: flex;
    /* margin-left: 100px; */
    background-color: #d3d3d3;
    width: 60%;
    height: 70%;
    justify-content: center;
    position: relative;
    overflow: hidden;
  }
  
  .qr-imagename {
    color: rgba(82, 68, 125, 1);
  }
  
  .abha-card-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .info {
    text-align: center;
  }
  
  .subtitle {
    font-size: 1.2rem;
    color: #333;
  }
  
  .card-details {
    display: flex;
    align-items: center;
    margin-top: 1rem;
  }
  
  .image img {
    width: 100px;
    height: 100px;
  }
  
  .details {
    margin-left: 1rem;
  }
  
  .details p {
    margin: 0.2rem 0;
  }
  
  .qr-image {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* gap: 5%; */
    justify-content: center;
  }
  
  .qrname {
    color: rgba(11, 72, 118, 1);
    font-size: 27px;
  }
  
  .abha-number {
    display: flex;
    justify-content: center;
    gap: 5%;
    flex-direction: column;
  }
  
  .abha-number p {
    color: #5d4a87;
  }
  
  .abha-number .bold {
    font-weight: bold;
    margin-top: 0;
  }
  
  .qrimage-container {
    display: flex;
    justify-content: center;
    gap:12%;
    width: 50%;
  }
  
  /*******************************/
  
  .info {
    text-align: center;
  }
  
  /* .subtitle {
    font-size: 1.2rem;
    color: #333;
  } */
  
  .card-details {
    display: flex;
    align-items: center;
    margin-top: 1rem;
  }
  
  .image img {
    width: 100px;
    height: 100px;
  }
  
  .details {
    margin-left: 1rem;
  }
  
  .details p {
    margin: 0.2rem 0;
  }
  