.Vidtitle {
  font-size: 16px;
  font-weight: 600;
  border: none;
  border-radius: 26px;
  color: #000000;
  padding: 1px 4px;
  font-family: "Inter";
}

.videoPlayerContainer {
  padding-top: 80px;
}

.vidLeftCont{
  /* width: 50%; */
  display: flex;
  flex-direction: column;
  gap: 2rem;
  overflow: scroll;
}

.videoItem {
    /* width: 448px; */
    /* height: 73px; */
    display: flex;
    background: #eeecf5;
    border-radius: 26px;
    padding: 20px 20px
}

.videocontainer {
  border: none;
  border-radius: 16px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.Loading{
  font-size: 40px;
  padding-top: 100%;
  /* padding-right: 70%; */
  padding-left: 80%;
}