.articles-hyper-pdf {
  height: 98%;
  padding: 3% 2% 2% 1%;
  margin-bottom: 20px;
  overflow: hidden;
  border: none;
}

.articles-hyper-image {
  margin-bottom: 20px;
}

.articles-hyper-image img {
  max-width: 97%;
  height: auto;
  display: block;
  margin: 0 auto;
}

.articles-hyper-pdf .rpv-default-layout__toolbar,
.articles-hyper-pdf .default-layout__sidebar {
  display: none;
}

.articles-hyper-pdf .rpv-default-layout__sidebar-headers {
  display: none;
}

.articles-hyper-link{
  margin-left: 28%;
  /* border: 2px solid rgba(82, 68, 125, 1); */
  border: 2px solid rgba(172, 197, 234, 1);
  margin: 3% 20% 1%;
  /* padding: 0% 1%; */
  padding: 0;
  background-color: rgba(247, 243, 254, 1);
  border-radius: 23px;
  display: flex;
  flex-direction: column;

}

.articles-hyper-link img{
  width: 100%;
  border-radius: 0px 0px 23px 23px;
}

.articles-hyper-link p{
  padding-top: 4%;
  font-size: 1rem;
  padding-left: 7%;
  padding-bottom: 4%;
  padding-right: 4%;
}

@media (max-width: 822px) {
  .articles-hyper-link p{
    padding-top: 6%;
    font-size: 12px;
    padding-left: 4%;
  }
}

.articles-hyper-link h3{
  padding-left: 7%;
}

