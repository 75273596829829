.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f2f2f2;
}

.table-striped tbody tr:nth-of-type(even) {
  background-color: rgba(242, 247, 255, 1);
}



.main-ManageABHA-container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  border: 1px solid #bcbbbb;
  border-radius: 19px;
}

.ManageABHA-container {
  display: flex;
  gap: 5%;
  /* margin: 1%; */
  width: 100%;
  height: 75vh;

  /* background: rgba(238, 232, 255, 1); */
  border-radius: 20px;
  /* padding: 1%; */
  flex-direction: column;
  align-items: center;
  /* box-shadow: 2px 1px 5px 10px rgba(241, 238, 250, 1); */
  /* border: 1px solid #bcbbbb ; */
}

.ManageABHA-title {
  color: rgb(136, 115, 201);
  width: 100%;
}

.ManageABHA-navcontents {
  display: flex;
  gap: 5%;
  /* background-color: rgba(247, 243, 254, 1); */
  border-radius: 30px;
  padding: 2%;
}

.consents-item {
  flex: 1;
  padding: 1% 2%;
  text-align: center;
  text-decoration: none;
  color: rgba(93, 74, 135, 1);
  background-color: rgb(255, 255, 255);
  border: 1px solid #c093e6;
  cursor: pointer;
  transition: background-color 0.3s;
  border-radius: 30px;
  font-size: 0.9rem;
  font-weight: 900;
}

/* .consents-item:hover {
    background-color: #af8dff;
  } */

.consents-selected {
  background-color: rgba(93, 74, 135, 1);
  color: white;
  border-radius: 30px;
  font-size: 1rem;
}

.ManageABHA-maincontent {
  width: 100%;
  margin-bottom: 1%;
  display: flex;
  justify-content: flex-end;
  padding-right: 22px;
}

thead[data-test="table-foot"] {
  display: none;
}

table {
  /* background-color: white !important; */
  border: none;
  width: 100%;
}

.MDB-concents th {
  height: 9vh;
  max-width: 100vw;
  border-collapse: collapse;
  border: none !important;
  background-color: rgb(152, 142, 142);
  color: white;
  /* position: sticky; 
  top: 0; 
  z-index: 1;  */

}

.mdbtable td {
  height: 6vh;
  max-width: 100vw;
  border: none;
}

.MDB-concents {
  width: 92VW;

}

.ManageABHA-button-container {
  display: flex;
  justify-content: center;
  margin: 1%;
  width: 15%;
}

.ManageABHA-create-button {
  background-color: #4caf50;
  color: white;
  padding: 1% 1%;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.ManageABHA-create-button:hover {
  background-color: #45a049;
}

.createConcent-aligment {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

.mdbtable {
  width: 100%;
  height: 60vh;
  overflow: scroll;
}

.col-sm-12 {
  width: 100%;
}

.Consents-table {
  margin: 1%;
  height: 58vh;
  display: flex;
  gap: 1;
  flex-direction: column;
  gap: 1%;
  width: 100%;
}

.mdbtable td {
  text-align: center;
  border: black !important;

}

.MDB-concents table {
  width: 100%;
  border: black !important;

}

.MuiDialogTitle-root {
  flex: 1 1 auto;
  margin: 0;
  padding: 12px 20px;
  display: flex;
  justify-content: center;
  background-color: rgba(214, 229, 255);
  font-weight: bolder;
}

.MuiDialog-paperWidthSm {
  max-width: 60% !important;
}

.ManageAbha-CheckboxLabel {
  color: rgba(82, 68, 125, 1);
  display: flex;
  margin: 2% 0% 2% 0%;
  font-size: 1.3rem;
}



/* .MuiButton-label{
  width: 100%;
  display: inherit;
  align-items: inherit;
  justify-content: inherit;
  background-color: rgba(82, 68, 125, 1);
  color: white;
  padding: 1%;
  border-radius: 7px;
} */

.MuiDialogActions-root {
  flex: 0 0 auto;
  display: flex;
  padding: 8px;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.MuiIconButton-label {
  width: 100%;
  display: flex;
  align-items: inherit;
  justify-content: inherit;
  color: rgba(82, 68, 125, 1);
}

.ManageAbha-healthdata {
  display: flex;
  flex-direction: row;
  gap: 15%;
}

.MuiTypography-h6 {
  font-size: 2rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: 0.0075em;
}

.ManageAbha-Checkbox {
  display: flex;
  flex-wrap: wrap;
  background-color: #EBF2FF;
  padding: 3%;
  border-radius: 16px;
}

.ManageAbha-Checkbox .MuiFormControlLabel-root {
  flex: 0 0 45%;
  box-sizing: border-box;
  /* margin-bottom: 10px;  */
}

.MuiFormControlLabel-root {
  display: flex;
  align-items: center;
}

.MuiFormControlLabel-label {
  flex: 1;
}

.Consent-buttons {
  background-color: rgba(82, 68, 125, 1);
  color: white;
}

.MuiTypography-h6 {
  font-size: 2rem;
}

.granted-consent-button {
  cursor: pointer;
}

.Consent-fetching {
  display: flex;
  justify-content: space-between;
}

.fetching-drcontent {
  display: flex;
  flex-direction: column;
  height: 15vh;
  justify-content: center;
}

/* ******* New css for consent ******* */


.AbhaConsenTtitle {
  background-color: #E3EDFF;
  padding: 2%;
  border-top-right-radius: 19px;
  border-top-left-radius: 19px;
  /* margin-bottom: 2%; */
}

.AbhaConsenTtitle p {
  font-size: 22px;
  font-weight: 800;
  color: #094A73;
}


.consent-patients-container {
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
  margin-bottom: 1%;
  max-height: 467px;
  overflow-y: auto;
  padding: 2%;
}

.Consent-Patients {
  width: 45%;
  border-radius: 18px;
  border: 1.8px solid rgb(214, 229, 255);
}

.Consent-Patients .Patients {
  display: flex;
  justify-content: space-around;
  padding: 2%;
  background-color: rgb(214, 229, 255);
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  width: 100%;
  gap: 10%;
  align-items: center;
}

.Consent-Patients .Patients h3 {
  font-size: 15px;
  color: #52447D;
  font-weight: 800;
}

.Consent-Patients .Consentbtn {
  background-color: rgb(251, 175, 66);
  padding: 1%;
  border-radius: 18px;
  border: none;
  width: 89px;
  height: auto;
  color: white;
  padding: 5%;
}

.Consent-Patients .Patients-details {
  padding: 2%;
  margin: 2%;
  gap: 10px;
  padding-left: 6%;
}

.Consent-Patients .Patients-details p {
  align-items: baseline;
  font-size: 14px;
  padding: 1%;
  font-weight: 400;
  /* display: flex; */
  gap: 3%;
}

.patientIdentifier {
  background-color: #45a049;
}

.MuiFormControl-marginDense {
  width: 40% !important;
  /* border: 1px solid black !important;
  border-radius: 12px;
  border-bottom: none!important; */
}

.MuiFormControl-fullWidth {
  width: 44% !important;
}


.Patientspan {
  font-size: 15px;
}


.MuiDialog-paperScrollPaper {
  width: 50%;
}


.AbhaConsentButton {
  width: 50%;
}

.AbhaConsentButtonouter {
  display: flex;
  padding: 0.7rem;
  justify-content: center;
  background: #F9FBFF;
  border-radius: 20px;

}

@media (max-width: 194px) {
  .consent-patients-container {
    /* flex-direction: column; */
    gap: 52px !important;
    width: 100% !important;
  }

}

@media (max-width: 822px) {
  .consent-patients-container {
    gap: 10px;
    /* width: 100%; */
  }

  .Consent-Patients {
    flex: 33%;
    width: 50%;

  }

  .Consent-Patients .Patients-details p {
    align-items: baseline;
    font-size: 12px !important;
    padding: 1%;
    font-weight: 400;
    display: flex;
    gap: 3%;
  }



  .Patientspan {
    font-size: 10px !important;
  }

  .ManageABHA-navcontents {
    display: flex;
    gap: 1%;
    /* background-color: rgba(247, 243, 254, 1); */
    /* border-radius: 30px; */
    /* padding: 1%; */
    /* width: 50%; */

  }

  .consents-selected {
    /* background-color: rgba(93, 74, 135, 1); */
    color: white;
    border-radius: 30px;
    font-size: 8px;
    /* padding: 4%!important; */
  }






  .Patients-details {
    text-align: center;
  }
}

/* Mobile styles */
@media (max-width: 320px) {


  .consent-patients-container {
    /* flex-direction: column; */
    gap: 10px;
    width: 100%;
  }

  .Consent-Patients {
    flex: 33%;
    width: 50%;

    /* margin-bottom: 20px; */
  }

  .Patients-details p {
    font-size: 14px;
  }

  /* .Consentbtn {
    width: 100%;
  }

  .Patients-details {
    padding: 10px;
  } */
}