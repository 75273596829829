@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,800;1,700&family=Open+Sans:wght@400;700&family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,800;1,200;1,400;1,500;1,700;1,900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,800;1,700&family=Open+Sans:wght@400;700&family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,800;1,200;1,400;1,500;1,700;1,900&family=Viga&display=swap");

body {
  /* font-family: "Montserrat", sans-serif; */
  font-family: "Open Sans", sans-serif;
  /* font-family: "Poppins", sans-serif; */
  /* font-family: "Montserrat", sans-serif; */
  /* font-family: "Open Sans", sans-serif; */
  /* font-family: "Viga", sans-serif; */

}

/* ::-webkit-scrollbar{
  width: 10px;
}

::-webkit-scrollbar-thumb{
  background:rgba(146, 137, 248, 0.6);
} */

button{
  cursor: pointer;
}