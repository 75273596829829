.bgAppointmentsPatientsBodyContainerAPM .calendarSection{
    border: 1px solid rgba(172, 197, 234, 1);
    border-radius: 14px;
    max-height: 515px;
    /* min-width: 380px; */
    background-color: #FFF;
    flex: 2;
    width: 41%;
    height:464px;
  }

.calendarSection .CalenderHeading {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    /* background: linear-gradient(180deg, #6688ff 0%, #0c3ce7 100%); */
    background-color: #a1b5ff;
    color: white;
    padding: 12.5px 30px;
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
  }

.CalenderHeading h2 {
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0.12em;
    text-align: left;
  }
  
.CalenderHeading .innerCalenderheading{
    font-family: Outfit;
    font-size: 24px;
    font-weight: 600;
    line-height: 30.24px;
    letter-spacing: 0.12em;
    text-align: left;
    color:#FFFFFF;
  }

.CalenderAddButtonContainer {
    display: flex;
    justify-content: flex-end;
    border-bottom-left-radius: 14px;
    border-bottom-right-radius: 14px;
    margin: auto 15px 20px auto;
  }

.CalenderAddButtonContainer .CalenderLink1 {
    color: white;
  }

.CalenderLink1 .addCalenderButton1 {
    width: 200px;
    height: 35px;
    border-radius: 28.5px;
    background-color:#52447D;
    font-size: 12px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0.05em;
    color: white;
    border: none;
    cursor: pointer;
    /* margin-top:30px; */
  }

  @media screen and (min-width: 1024px) {
   .calendarSection{
    width:34%;
   }
  }

  @media screen and (min-width: 768px) {
    .calendarSection{
     width:28%;
    }
   }

  .calendarSection .css-1vskg8q{
    display: none !important;
  }
  
  .calendarSection .css-5oi4td{
    /* height: 255px !important; */
    
  }

  .css-1ho05b .MuiPickersLayout-toolbar{
    display: none;
  }