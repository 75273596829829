.layoutRight{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #f9fbff;
    height: 100vh;
}


.layoutRight_1{
    display: flex;
    flex-direction: column-reverse;
    /* width: 100%; */
    height: 90vh;
    /* overflow: scroll; */
    margin: 1rem 1rem 0rem 1rem;
    border-radius: 11px;

}
.layoutRight_1_1{
    height: 87.5vh;
    /* border: 2px solid #4affe9; */
    background: rgb(249,251,255) !important;
    width: 100%;
    /* padding: 0px 13px; */
    display: flex;
    /* overflow: scroll; */
    gap: 1rem;
}
.layoutRight_1_child_1{
    flex: 1.3;
    /* width: 35%; */
    margin: 0.5rem;
    overflow: scroll;
    margin-bottom: 0;
    padding: 0;
}
.layoutRight_1_child_2{
    flex: 3.7;
    margin: 0.7rem;
    overflow: auto;
    margin-bottom: 0;
    padding: 0;
}
