.patientinfo {
  width: 100%;
  text-align: center;
  background-color: #f2f7ff;
  margin-top: 1%;
  padding: 0.5%;
  border-radius: 10px;
  display: flex;
}

.searchBox {
  width: 100%;
  padding-top: 1%;
  border: none;
  display: flex;
  color: "#BEBEBE";
}

.searchBox:focus {
  border: none;
  outline: none;
}

.patientname {
  text-align: start;
  width: 30%;
  font-size: 100%;
}

.patienticon {
  background-color: #4affe9;
  color: #000000;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 110%;
  margin-right: 1%;
  margin-top: 1.5%;
}

.patientinfo2 {
  font-size: 70%;
  text-align: start;
  width: 30%;
  display: flex;
  flex-direction: column;
}

#image {
  width: 9%;
  margin-right: 2%;
}

.css-2hwdtx {
  justify-content: space-between;
}

.fllowups {
  margin: 1% 1%;
  text-align: center;
  background-color: white;
  border-radius: 10px;
}

h6 {
  padding: 2%;
}

/* RemindersData Some CSS part */

.ReminderListandProfile {
  display: flex;
  height: 72vh;
  margin-top: 2%;
}

.Detailsboxsize {
  width: 64%;
  margin-right: 3%;
}

.PatientNamesize {
  font-size: 20px;
  padding: 2%;
  width: 100%;
}

.Genderfont {
  display: flex;
  padding-bottom: 1%;
  padding-top: 1%;
  font-size: 15px;
}

.idfont {
  display: flex;
  font-size: 15px;
}

.Mobnumfont {
  display: flex;
  font-size: 15px;
}

.FolloupsHomeContainer {
  display: flex;
}

.FollowupsBackground {
      /* margin: 5%; */
    /* background-color: rgb(242, 247, 255); */
    /* height: 92vh; */
    /* overflow: scroll; */
    width: 100%;
}

.Followupsbtn {
  width: 200px;
  height: 27px;
  font-size: 17px;
  font-weight: 800;
  color: #544380;
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: rgba(82, 68, 125, 1) !important;
  font-size: 1rem;
  margin-top: 20%;
}

.css-1aquho2-MuiTabs-indicator{
  background-color: rgba(82, 68, 125, 1) !important;
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root{
  margin-top: 5% !important;
}

.css-2hwdtx{
  background-color: 1px solid rgba(228, 228, 228, 1) !important;
}

.MuiBox-root css-2hwdtx{
  margin-top: 2% !important;
}

.FolloupSearchMain {
  gap: 5%;
  display: flex;
  padding: 0rem 2rem 0rem 0.8rem;
  flex: 1 1;
  align-items: center;
  justify-content: space-between;
  margin: 1%;

}

.PatientSearch {
  background-color: white;
  display: flex;
  flex-direction: row;
  border: 1px solid rgba(206, 206, 206, 1);
  border-radius: 25px;
  align-items: center;
  gap: 3%;
  width: 20%;
  padding: 0.5%;
  justify-content: flex-start;
}

.FollowupPatient {
  padding-top: 1%;
  
  border: none;
  display: flex;
  /* color: #BEBEBE !important;  */
  width: 180% !important;
}


.followupsScroll{
  height: 60vh;
  overflow: scroll;
}


@media (max-width: 822px) {
  .FollowupPatient {
    padding-top: 1%;
    
    border: none;
    display: flex;
    /* color: #BEBEBE !important;  */
    width: 180% !important;
  }
  .PatientSearch {
    background-color: white;
    display: flex;
    flex-direction: row;
    border: 1px solid rgba(206, 206, 206, 1);
    border-radius: 25px;
    align-items: center;
    gap: 3%;
    width: 37%;
    padding: 1%;
    justify-content: flex-start;
  }
  
}

.FollowupPatient:focus {
  outline: none; 
}

.FollowUpsTable{
  height: 90vh;
  /* overflow: scroll; */
  margin: 2% 3% 3%;
}

.css-2hwdtx{
  padding-left: 2%;
  border-bottom: 1px solid #E4E4E4 !important;

}

.FollowupProfile{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 39px;
  height: 39px;
  border-radius: 50%;
  background-color: rgba(238, 225, 255, 1);
  /* border: 1px solid #4affe9; */
  /* border: 1px solid #4affe9; */
  flex-direction: column;
  font-size: 71%;
}

@media (max-width: 822px) {
  .FollowupProfile{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    background-color: rgba(238, 225, 255, 1);
    /* border: 1px solid #4affe9; */
    /* border: 1px solid #4affe9; */
    flex-direction: column;
  }
}


