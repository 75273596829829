

.logo {
  width: 100px;
  height: 100px;
  margin-top: 10px;
}




.topNavBarItems {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1% 0%;
  padding-left: 5%;
  padding-right: 5%;
  background: white;
}

.TopNavbar-iteams {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* gap: 18%; */
}

.TopNavbar-hello {
  color: rgba(115, 119, 145, 1);
  font-size: 15px;
  font-weight: 400;
}

.TopNavbar-doctorName {
  color: rgba(21, 29, 72, 1);
  font-size: 15px;
  white-space: nowrap;
  padding-right: 10px;
  font-weight: bold;
}

.Profile-Image {
  width: 2rem;
  height: 2rem;
  border-radius: 20px;
}

.topNavBarHeader{
  /* position: fixed; */
}



@media screen and (max-width: 1236px) {

}
