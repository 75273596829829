.login {
  display: flex;
  flex-direction: column;
  background-color: #f6f6f6;
  height: 100vh;
  position: relative;
}

.loginHeader {
  display: flex;
  /* justify-content: space-around; */
  align-items: center;
  padding: 2% 10% 0% 8%;
  background-color: #f6f6f6;
}

.Clogo {
  width: 70px;
  /* height: 20px;
  padding-right: 15px;
  padding-bottom: 8px; */
}

.logo-Resetpin {
  display: flex;
  gap: 5%;
  align-items: center;
  /* padding-right: 60%; */
  width: 100%;
}

.logo-Resetpin h1 {
  padding-top: 7%;
}

.button-box {
  width: 240px;
  position: relative;
  border-radius: 30px;
  display: flex;
  height: 40px;
  justify-content: space-between;
  margin-bottom: 5%;
  /* margin-left: 20%; */
}

.toggle-btn1,
.toggle-btn2 {
  padding: 10px 30px;
  cursor: pointer;
  position: absolute;
  background: transparent;
  font-size: 18px;
  font-weight: 400;
  color: #52447d;
  border: 0;
  border-bottom: 5px;
  z-index: 5;
  outline: none;
  position: relative;
  white-space: nowrap;
}

.toggle-btn1 {
  top: 0;
  right: -4.5px;
}

.toggle-btn2 {
  top: 0;
  left: 14px;
}

#under_line {
  position: absolute;
  background-color: white;
  bottom: 4px;
  width: 30px;
  height: auto;
  border-bottom: 3px solid blueviolet;
  transition: all 300ms ease-in-out;
}

#btn {
  top: 0%;
  right: 0%;
  position: absolute;
  background-color: white;
  z-index: 1;
  width: 110px;
  height: 100%;
  border: 1px solid #52447d6e;
  box-shadow: 0px 1px 4px 0px #52447d6e;
  border-radius: 30px;
  transition: 0.2s ease-in-out;
  transition: all 300ms ease-in-out;
}

.body {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2px 200px 0px 150px;
  min-height: 70vh;
  background-color: #f6f6f6;
  /* background-image: url("../images/apple.svg"); */
}

.computer-image {
  width: 29vw;
  height: 50vh;
  background-color: transparent;
  /* padding-top: 30px; */
}

.doctor-image {
  width: 45vw;
  height: 90vh;
  background-color: transparent;
  margin-right: 280px;
  padding-top: 30px;
  position: absolute;
  bottom: 0;
  left: 0;
}

form {
  display: flex;
  flex-direction: column;
  animation: slide-left 300ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
  width: 25vw;
  /* margin-top: 13%; */
}

.Reset-form {
  display: flex;
  flex-direction: column;
  animation: slide-left 300ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
  width: 25vw;
  /* margin-top: 13%; */
}

.image-container-Reset {
  width: 73%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image-container-password {
  width: 73%;
}

.logo-container-password {
  padding-top: 1%;
  padding-left: 14%;
  width: 73%;
  gap: 2%;
  display: flex;
  align-items: center;
}

.logo-container-password h1 {
  padding-top: 2%;
}

.names {
  display: flex;
  gap: 20px;
}

.input {
  outline: none;
  background-color: #f3eeff;
  border-radius: 7px;
  height: 60px;
  width: 100% !important;
  border: none;
  padding: 19px 40px;
  border-radius: 7px;
  color: #4f555a;
  font-weight: 400;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  transition: all 10s ease-in-out;
  animation: inputs 300ms ease-in-out;
}

.loginOptions {
  display: flex;
  padding: 0 10px;
}

.resend-otp {
  border: none;
  background-color: transparent;
  /* text-align: right; */
  font-size: 17px;
  font-weight: 400;
  color: #8e8e8e;
  margin-top: 6px;
  cursor: pointer;
  transition: all 10s ease-in-out;
  animation: inputs 300ms ease-in-out;
}

.send-otp {
  border: 1px solid #52447d;
  background-color: #52447d;
  border-radius: 7px;
  height: 60px;
  color: white;
  width: 100%;
  margin-top: 30px;
  font-size: 18px;
  font-weight: 700;
  line-height: 21px;
  cursor: pointer;
  transition: all 80ms ease-in-out;
  animation: inputs 300ms ease-in-out;
}

.send-otp:hover {
  box-shadow: 0px 3px 16px 1px #52447d75;
}

.orContinueWith {
  display: flex;
  align-items: center;
  margin-top: 30px;
  animation: inputs 300ms ease-in-out;
}

.orContinueWith span {
  color: #8f8f8f;
  display: inline-block;
  padding: 0px 10px;
}

.loginOptions {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  animation: inputs 300ms ease-in-out;
}

.wrapper {
  width: 90px;
  height: 47px;
  border-radius: 8px;
  border: 1px solid #d9d9d9;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 80ms ease-in-out;
}

.wrapper:hover {
  box-shadow: 0px 1px 4px 0px #00000040;
  background-color: white;
}

.headerReset {
  padding: 2% 10% 0% 8%;
}

.image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 1024px) {
  .body {
    padding: 2px 60px 30px;
  }

  .input {
    height: 40px;
    margin: 0%;
  }

  .send-otp {
    height: 40px;
  }

  .headerReset {
    padding: 2% 10% 0% 8%;
  }

  form {
    /* width: 32vw; */
  }

  .toggle-btn1,
  .toggle-btn2 {
    padding: 0px 30px;
    cursor: pointer;
    position: absolute;
    background: transparent;
    font-size: 18px;
    font-weight: 400;
    color: #52447d;
    border: 0;
    border-bottom: 5px;
    z-index: 5;
    outline: none;
    position: relative;
    white-space: nowrap;
  }

  .resend-otp {
    font-size: 13px;
  }
}

@media (max-width: 768px) {
  /* input {
    width: 250px;
    height: 50px;
  } */

  .clinally-logo {
    width: 4rem !important;
  }

  .clinallytext {
    font-weight: bold;
    font-size: 20px !important;
    color: #52447d;
  }
}

@keyframes slide-left {
  0% {
    transform: translateX(160px);
  }

  100% {
    transform: translateX(0px);
  }
}

@keyframes inputs {
  0% {
    transform: translateY(-160px);
  }

  100% {
    transform: translateY(0px);
  }
}

.clinallytext {
  font-weight: bold;
  font-size: 27px;
  color: #52447d;
}

.clinallyPragposition {
  margin: 5% 15% 0 0%;
  /* // border: "2px solid black", */
  border: none;
  background-color: #f2f2f2;
  border-radius: 10px;
  padding: 2%;
}

.Resetpasstext {
  color: #52447d;
  /* padding-left: 13%; */
}

.resetpasscontainer {
  display: flex;
  align-items: center;
  gap: 5%;
  width: 100%;
  justify-content: center;
  margin-top: 13%;
}

.AIIMSCDCCImgposition {
  display: flex;
  /* // flexDirection: "column", */
  justify-content: center;
  gap: 4rem;
  align-items: center;
}

.AIIMSCDCCImgGap {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
}

.cdccimgtext {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
}

.logo-container {
  width: 40%;
  margin-bottom: 2%;
}

.LandingImage {
  display: flex;
  align-items: center;
  gap: 5%;
  width: 100%;
  justify-content: center;
  margin-top: 5%;
  flex-direction: column;
}

.clinally-logo {
  width: 6rem;
}

.input-pin{
  animation: inputs .3s ease-in-out;
    -webkit-appearance: none;
    appearance: none;
    background-color: #f3eeff;
    border: none;
    border-radius: 7px;
    color: #4f555a;
    font-size: 18px;
    font-weight: 400;
    height: 60px;
    line-height: 22px;
    outline: none;
    padding: 19px 40px;
    transition: all 10s ease-in-out;
    width: 100% !important;
    margin-bottom: 2%;
}
