
.default-values{
    color: #ACC5EA;
}

.diabetes-text{
    font-size: 15px;
    font-weight: 400;
    line-height: 16npx;
    color: 
    rgba(82, 68, 125, 1);
}

.row-container select {
  border:1px solid rgba(70, 95, 241, 0.4);;
    padding: 5px;
    border-radius: 8px;
    width:312px;
}

.row-container1 select{
    border: solid 1px #ACC5EA;
    padding: 5px;
}

.row-container2{
    display: flex;
    justify-content: space-between;
    gap: 3%;
    flex-direction: column;
    gap: 10px;
    overflow-x: auto;
}

.row-container2 select{
border: solid 1px #ACC5EA;

}


  