.TableComponent .custom-table {
  width: 100%;
  border-spacing: 0 10px;
  border-collapse: separate;
  border: none;
  background-color: transparent !important; 
}

.TableComponent .custom-table th {
  background-color: none;
  font-weight: bold;
  /* Remove default border */
  
}

.TableComponent .custom-table th
 {
  text-align: center;
  vertical-align: middle;
  padding: 10px 1px;
  text-align: left;
  color: rgba(193, 193, 193, 1);
  padding-left: 26px;
  border: none;
}

.TableComponent .custom-table td {
  text-align: center;
  vertical-align: middle;
  padding: 10px 1px;
  text-align: left;
  color: rgba(82, 68, 125, 1);
  padding-left: 26px;
  border: none;
}

/*Table heading*/
/* .TableComponent .custom-table th:first-child {
  border-top: 1px solid rgba(206, 206, 206, 1);
  border-left: 1px solid rgba(206, 206, 206, 1);
  border-bottom: 1px solid rgba(206, 206, 206, 1);
  border-right: none;
  border-radius: 15px 0 0 15px ; 
}

.TableComponent .custom-table th {
  
  border-top: 1px solid rgba(206, 206, 206, 1);
  border-bottom: 1px solid rgba(206, 206, 206, 1);
}

.TableComponent .custom-table th:last-child {
  border-left: none;
  border-top: 1px solid rgba(206, 206, 206, 1);
  border-right: 1px solid rgba(206, 206, 206, 1);
  border-bottom: 1px solid rgba(206, 206, 206, 1);
  border-radius: 0 15px 15px 0; 
} */

/*Table row*/
.TableComponent .custom-table td:first-child {
  border-top: 1px solid rgba(206, 206, 206, 1);
  border-left: 1px solid rgba(206, 206, 206, 1);
  border-bottom: 1px solid rgba(206, 206, 206, 1);
  border-right: 2px solid rgba(206, 206, 206, 1);
  border-radius: 15px 0 0 15px ; 
}

.TableComponent .custom-table td {
  border-top: 1px solid rgba(206, 206, 206, 1);
  border-bottom: 1px solid rgba(206, 206, 206, 1);
}

.TableComponent .custom-table td:last-child {
  border-left: none;
  border-top: 1px solid rgba(206, 206, 206, 1);
  border-right: 1px solid rgba(206, 206, 206, 1);
  border-bottom: 1px solid rgba(206, 206, 206, 1);
  border-radius: 0 15px 15px 0; 
}

.TableComponent .custom-table td {
  color: rgba(9, 74, 115, 1);
  font-size: 15px;
}

.TableComponent .custom-row {
  color: rgba(9, 74, 115, 1);
  background-color: #fff;
  transition: transform 0.3s;
  cursor: pointer;
  border: 10px solid black; /* Set the border to 10px solid black */
  border-radius: 0; /* Remove border radius */
}
.TableComponent .custom-row:hover{
  background-color: #ece4e4;
}
.TableComponent .profile-pic {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.TableComponent .link-abha-btn {
  background-color: rgba(212, 212, 212, 1); /* Custom button color */
  color: rgba(9, 74, 115, 1);
  border-radius: 20px;
  font-size: 14px;
  padding: 5px 15px;
  border: none;
  text-transform: none; /* Prevent all caps */
  cursor: pointer;
}

.TableComponent .three-dots {
  color: #999;
  cursor: pointer;
  margin-left: 10px;
}

.TableComponent .three-dots:hover {
  color: #666;
}

.TableComponent .table-light {
  background-color: white;
  border: 1px solid rgba(206, 206, 206, 1);
}

.TableComponent .table-light tr {
  border: none;
}

.TableComponent .table-light .custom-row {
  border-radius: 0; /* Reset border radius */
}

@media screen and (max-width: 768px) {

  .TableComponent .custom-table td {
    color: rgba(9, 74, 115, 1);
    font-size: 10px;
  }

.TableComponent .custom-table th,
.TableComponent .custom-table td {
  text-align: center;
  vertical-align: middle;
  /* padding: 4px 0px; */
  text-align: left;
  color: rgba(82, 68, 125, 1);
  padding-left: 20px;
  /* border: none; */
}

.TableComponent .custom-table th {
  font-size: 12px;
  border-top: 1px solid rgba(206, 206, 206, 1);
  border-bottom: 1px solid rgba(206, 206, 206, 1);
}

}


