
.createHfridbuttons{
    width: 10%;
    height: 40px;
    border-radius: 5.5px;
    /* background: linear-gradient(
      180deg,
      rgb(153.79, 151.73, 255) 10.78%,
      rgb(95, 78, 134) 90.15%
    ); */
    background-color: #52447d;
    font-size: 14px;
    font-weight: 400;
    /* line-height: 23px; */
    letter-spacing: 0.05em;
    box-shadow: 0px 1px 4px 0px #00000040;
    color: white;
    border: none;
    cursor: pointer;
    margin-right: 2%;
}

.hfridbutton{
    display: flex;
    justify-content: flex-end;
    /* gap: 2%; */
}