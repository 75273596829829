.FollowUpsTable .custom-table {
  width: 100%;
  border-spacing: 0 10px;
  border-collapse: separate;
  border: none;
  background-color: transparent !important;
}

.FollowUpsTable .custom-table th {
  background-color: none;
  font-weight: bold;
  border: none;
  height: 32px;
}

.FollowUpsTable .custom-table th,
.FollowUpsTable .custom-table td {
  text-align: center;
  vertical-align: middle;
  /* padding: 10px 1px; */
  text-align: left;
  color: rgba(193, 193, 193, 1);
  padding-left: 18px;
  border: none;
}

.FollowUpsTable .custom-table td:first-child {
  position: relative;
}


.FollowUpsTable .custom-table td:first-child::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 2px;
  background-color: rgba(237, 237, 237, 1);
}

/*Table row*/
.FollowUpsTable .custom-table td:first-child {
  border-top: 1px solid rgba(206, 206, 206, 1);
  border-left: 1px solid rgba(206, 206, 206, 1);
  border-bottom: 1px solid rgba(206, 206, 206, 1);
  border-right: none;
  border-radius: 15px 0 0 15px;
  width: 8%;
  padding: 0.4%;
  padding-left: 2%;
}

@media (max-width: 822px) {
  .FollowUpsTable .custom-table td:first-child {
    border-top: 1px solid rgba(206, 206, 206, 1);
    border-left: 1px solid rgba(206, 206, 206, 1);
    border-bottom: 1px solid rgba(206, 206, 206, 1);
    border-right: none;
    border-radius: 15px 0 0 15px;
    width: 8%;
    padding: 0.4%;
    padding-left: 1.3%;
  }


}

.FollowUpsTable .custom-table td {
  border-top: 1px solid rgba(206, 206, 206, 1);
  border-bottom: 1px solid rgba(206, 206, 206, 1);
}

.FollowUpsTable .custom-table td:last-child {
  border-left: none;
  border-top: 1px solid rgba(206, 206, 206, 1);
  border-right: 1px solid rgba(206, 206, 206, 1);
  border-bottom: 1px solid rgba(206, 206, 206, 1);
  border-radius: 0 15px 15px 0;
}

.FollowUpsTable .custom-table td {
  color: rgba(105, 105, 105, 1);
  font-size: 14px;
}

.FollowUpsTable .custom-row {
  color: rgba(9, 74, 115, 1);
  background-color: #fff;
  transition: transform 0.3s;
  /* cursor: pointer; */
  border: 10px solid black;
  /* Set the border to 10px solid black */
  border-radius: 0;
  /* Remove border radius */
}
/* .FollowUpsTable .custom-row:hover{
  background-color: #666;
} */

.FollowUpsTable .profile-pic {
  width: 40px ;
  height: 40px;
  border-radius: 50%;
}

.FollowUpsTable .link-abha-btn {
  background-color: rgba(212, 212, 212, 1);
  /* Custom button color */
  color: rgba(9, 74, 115, 1);
  border-radius: 20px;
  font-size: 14px;
  padding: 5px 15px;
  border: none;
  text-transform: none;
  /* Prevent all caps */
  cursor: pointer;
}

.FollowUpsTable .three-dots {
  color: #999;
  cursor: pointer;
  margin-left: 10px;
}

.FollowUpsTable .three-dots:hover {
  color: #666;
}

.FollowUpsTable .table-light {
  background-color: white;
  border: 1px solid rgba(206, 206, 206, 1);
}

.FollowUpsTable .table-light tr {
  border: none;
}

.FollowUpsTable .table-light .custom-row {
  border-radius: 0;
  /* Reset border radius */
}

@media screen and (max-width: 768px) {
  .FollowUpsTable .custom-table td {
    color: rgba(9, 74, 115, 1);
    font-size: 10px;
  }

  .FollowUpsTable .custom-table th,
  .FollowUpsTable .custom-table td {
    text-align: center;
    vertical-align: middle;
    /* padding: 4px 0px; */
    text-align: left;
    /* color: rgba(82, 68, 125, 1); */
    padding-left: 20px;
    /* border: none; */
  }

  .FollowUpsTable .custom-table th {
    font-size: 12px;
    /* border-top: 1px solid rgba(206, 206, 206, 1); */
    /* border-bottom: 1px solid rgba(206, 206, 206, 1); */
  }
}