.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.input {
    outline: none;
    background-color: #f3eeff;
    border-radius: 7px;
    height: 60px;
    width: 100% !important;
    border: none;
    padding: 19px 40px;
    border-radius: 7px;
    color: #4f555a;
    font-weight: 400;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    transition: all 10s ease-in-out;
    animation: inputs 300ms ease-in-out;
    -moz-appearance: textfield;
    appearance: none;
}