.css-z3c6am-MuiFormControl-root-MuiTextField-root {
  width: 100% !important;
  padding: 13px;
  background-color: #e3ebff;
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 13px;
  height: 0pc !important;
  /* background: #E3EBFF !important; */
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border: none !important;
  background: #eeecf5 !important;
  z-index: -1;
  border-radius: 1px !important;
  border: #52447d;
}

.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
  border-radius: 0 !important;
  border-radius: none !important;
}

.completeYourProfileHeading {
  font-family: Varela Round;
  font-size: 24px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0.01em;
  color: #52447d;
}

.MuiBox-root .css-rmz4vkn span {
  height: 75px !important;
  width: 75px !important;
}

circle {
  cx: 44;
  cy: 44;
  r: 18.2;
  fill: none;
  stroke-width: 4.5 !important; /* Updated stroke-width to 6 */
}

.css-oxts8u-MuiCircularProgress-circle {
  stroke: #52447d !important;
}

/* ProgressBar.css */

.progress-bar-container {
  width: 100%;
  background-color: #ebe3ff;
  border-radius: 5px;
  margin: 10px 0;
  overflow: hidden;
  /* height: 6px; */
}

.progress-bar {
  background-color: #52447d; /* Green */
  height: 5px;
  color: white;
  text-align: center;
  line-height: 30px;
  width: 0;
  /* animation: progressBarAnimation 2s ease-in-out; */
}

.info-space {
  margin-top: 10px;
  margin-bottom: 10px;
}

.progress-label {
  position: absolute;
  width: 100%;
  text-align: center;
}
.change-profile-prev-button {
  width: 142px;
  height: 32px;
  gap: 0px;
  border-radius: 5px;
  background-color: #52447d;
  font-family: Outfit;
  font-size: 15px;
  font-weight: 500;
  line-height: 18.9px;
  text-align: left;
  color: #ffffff;
  border: none;
  margin-right: 10px;
  text-align: center;
  cursor: pointer;
}

.change-profile-next-button {
  width: 142px;
  height: 32px;
  gap: 0px;
  border-radius: 5px;
  border: 1px 0px 0px 0px;
  background-color: #fff2f2;
  border: 1px solid #ff5656;
  font-family: Outfit;
  font-size: 15px;
  font-weight: 500;
  line-height: 18.9px;
  text-align: left;
  color: #ff5656;
  text-align: center;
  cursor: pointer;
}
.vector-profile-img {
  width: 12.65px;
  height: 15.83px;
  gap: 0px;

  color: #52447d;
  margin-right: 10px;
}
.personal-info-section {
  display: flex;
}
.personal-info {
  font-family: Outfit;
  font-size: 17px;
  font-weight: 500;
  line-height: 21.42px;
  text-align: left;
  color: #52447d;
}

.personnal-info-form-section {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  width: 50%;
}
.profile-input-text {
  width: 80%;
  height: 52px;
  gap: 0px;
  border-radius: 10px;

  border: 1px solid #dfdfdf;
  padding-left: 10px;
  padding-right: 10px;
}

.profile-input-text:disabled {
  cursor:no-drop;
}

.profile-input-text.active {
  border-color: #66afe9;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6);
}

.name-description .space {
  font-family: Outfit;
  font-size: 13px;
  font-weight: 400;
  line-height: 16.38px;
  text-align: left;
  color: #52447d;
}

.edit-icon {
  width: 19px;
  height: 19px;
  margin-left: -30px;
  margin-bottom: -5px;
  cursor: pointer;
}

.input-feilds-container {
  display: flex;
  margin-bottom: 20px;
}
.input-feilds-containeraddress {
  display: flex;
  margin-bottom: 20px;
  flex-direction: column;
  gap: 20px;
}

.profile-wide {
  width: 95%;
}
